import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthService } from '../services/auth-service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(
              private authService: AuthService,
              private msalService: MsalService,
              private socialAuthService: SocialAuthService,
              private router: Router) {
               }

  ngOnInit(): void {
    this.setUserInfoSubscription();
    this.authService.refreshLoginState();
  }

  setUserInfoSubscription(): void {
    const isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;

    this.socialAuthService.authState
              .subscribe(socialUser => {
                const isLoggedIn = !!socialUser;
                if (!isLoggedIn && !isAuthenticated) { //foo
                  this.router.navigate(['/']);
                }
              });
  }

}
