
<div>
  <!-- <span class="cards-container" *ngFor="let product of productList">
    <mat-card class="product-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
      <mat-card-content>
        <p>
          The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
          A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
          bred for hunting.
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
  </span> -->
</div>

<!-- <div class="row">
  <div class="col-sm-6">
    <div class="card product-card">
      <div class="card-body">
        <h5 class="card-title">Special title treatment</h5>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card product-card">
      <div class="card-body">
        <h5 class="card-title">Special title treatment</h5>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="cards-container">
    <span class="cards-container" *ngFor="let product of productList">
    <mat-card class="product-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Shiba Inu</mat-card-title>
        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image class="card-image" src="../../../../assets/thangkas/Buddha/1_Buddha_1.jpg" alt="Photo of a Shiba Inu">
      <mat-card-content>
        <p>
          The Shiba Inu is the smallest of the six original
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
  </span>
</div> -->

<div class="cards-container">
  <!-- <div class="card product-card" *ngFor="let product of productList">
    <div class="card-body">
      <h5 class="card-title">Special title treatment</h5>
      <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
      <a href="#" class="btn btn-primary">Go somewhere</a>
    </div>
  </div> -->

  <ng-container *ngIf="isReady">
    <mat-card class="product-card" *ngFor="let artwork of artworkProducts">
      <mat-card-header class="title">
        <mat-card-title class="text-center">{{artwork.artworkTitle}}</mat-card-title>
      </mat-card-header>
      <img mat-card-image class="card-image" [src]="artwork.imageUrl" (click)="displayImageDetail(artwork.artworkId)" [alt]="artwork.artworkTitle">
      <mat-card-content>
        <!-- <p class="card-text">
          Giclee print comes with 2 inch white border for handling
        </p> -->
        <select [id]="artwork.artworkId+'-select'" class="form-select-sm mt-2" aria-label="Print dimension" (change)="onSelectChange($event)">
          <option selected disabled>Select a size (w x h)</option>
          @for (prod of artwork.products; track prod) {
          <option [value]="prod.id">
            <span>
              {{prod.width}}" X {{prod.height}}" --------- ${{prod.price}}
            </span>
          </option>
          }
        </select>
      </mat-card-content>
      <mat-card-actions class="buy-button-container">
        <!-- <div>Foo: {{buttonsStateHash[artwork.artworkId]}}</div> -->
        <ng-container *ngIf="buttonsStateHash[artwork.artworkId]">
          <button [disabled]="!buttonsStateHash[artwork.artworkId]" [id]="artwork.artworkId" class="buy-button mr-5" mat-raised-button color="primary" (click)="buy(artwork.artworkId)">Buy</button>
        </ng-container>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>

