import { MatDialogRef } from "@angular/material/dialog";
import { MatMenuItem } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LoginComponent } from "src/app/login/login.component";
import { MicrosoftUser } from "src/app/models/microsoft-user.model";
import { UserInfo } from "src/app/models/user-info.model";
import { AuthService } from "src/app/services/auth-service";
import { IdentityService } from "src/app/services/identity.service";
import { RoleClaimName, UserRoleService } from "src/app/services/role-services";
import { SessionService } from "src/app/services/session.service";
import { SocialMediaAuthService } from "src/app/services/social-media-auth.service";
import { navbarMainDefinition, NavbarMenuItem } from "./definitions/main.definition";
import { FeatureFlagService } from "src/app/services/feature-flag.service";

export abstract class NavbarBaseComponent {
    private subs: Subscription[] = [];
    definition: NavbarMenuItem[] | undefined;
    authenticatedUser: MicrosoftUser | undefined;
    userInfoList: UserInfo[] = [];
    userInfo: UserInfo;
    isUserLoggedIn: boolean;
    isReady: boolean = false;
    matDialogRef:MatDialogRef<LoginComponent, any>;
    isUserAdmin: boolean;

    constructor(
        protected authService: AuthService,
        protected userRoleService: UserRoleService,
        protected identityService: IdentityService,
        protected sessionService: SessionService,
        protected router: Router,
        protected socialMediaAuthService: SocialMediaAuthService,
        protected featureFlagService: FeatureFlagService
        ) {
        }

    protected initSubscriptions(): void {
        this.setRoleClaimsSubscription();
        this.setUserLoginStatusChangeSubscription();

        // this.sessionService.webApiErrorResponse$
        //     .subscribe(x => this.hasWebApiError = x
        //     );
    }

    removeSubscriptions(): void {
        this.subs.forEach(x => x.unsubscribe());
    }

    setUserLoginStatusChangeSubscription(): void {
      this.isReady = false;

      this.subs.push(
          this.authService.userInfo$.subscribe((user) => {
            if (user?.authenticationAuthority === 'Microsoft') {
              this.isUserLoggedIn = user.isAuthenticated;

              if (this.isUserLoggedIn) {
              /* Remove existing entry for the same provider */
                this.userInfoList = this.userInfoList.filter(x =>
                  x.authenticationAuthority !== user.authenticationAuthority);
                this.userInfoList.push(user);

                /* Set userInfo to currently logged in account. There should be just one account logged in */
                this.userInfo = user;

                this.isUserAdmin = this.userInfo.claims?.some(x => x === RoleClaimName.Admin)

                if (this.matDialogRef) {
                  this.matDialogRef.close();
                }
              }
            }
          })
      );

      this.subs.push(
        this.socialMediaAuthService.userInfo$.subscribe((user) => {
           this.isUserLoggedIn = false;
           if (this.socialMediaAuthService.socialMediaProviders.some(x => x === user?.authenticationAuthority)) {

             this.isUserLoggedIn = user.isAuthenticated;
              if (this.isUserLoggedIn) {
                this.userInfoList = this.userInfoList.filter(x =>
                                  x.authenticationAuthority !== user.authenticationAuthority);

                this.userInfoList.push(user);

                /* Set userInfo to currently logged in account. There should be just one account logged in */
                this.userInfo = user;

                if (this.matDialogRef) {
                  this.matDialogRef.close();
                }

              }
           }
        })
    );
    setTimeout(() => this.isReady = true, 500);
  }

    setRoleClaimsSubscription(): void {
        // this.subs.push(
            // this.userRoleService.roleClaims$.subscribe((data) => {
            //     this.roleClaims = data;

            //     if (data?.length > 0) {
            //         this.setNavbarDefinition();

            //         this.authenticatedUser = this.sessionService.currentSession.authenticatedUser;
            //     }
            // }));
    }

    private setNavbarDefinition(): void {
        this.definition = navbarMainDefinition;

        this.filterRootLevelMenu();
        this.disableRestrictedChildMenuItems();
    }

    private filterRootLevelMenu(): void {
        // this.navbarDefinition = this.setNavbarDefinition.filter((x) => {
        //     if (!x.validRoles) {
        //         return true;
        //     }
        //     return x.validRoles?.some((y) => this.roleClaims?.some((n) => y === n));
        // });
    }

    disableRestrictedChildMenuItems(): void {
        this.definition?.forEach(x => {
            if (x.children) {
                x.children.forEach(cmi => {
                    let childMenuItem = (cmi as NavbarMenuItem);
                    this.setRestrictedMenuItemDisabled(childMenuItem);

                    if (childMenuItem.children) {
                        childMenuItem.children.forEach(gcmi => {
                            let grandChildMenuItem = (gcmi as NavbarMenuItem);
                            this.setRestrictedMenuItemDisabled(grandChildMenuItem);

                            if (grandChildMenuItem.children) {
                                grandChildMenuItem.children.forEach(ggci => {
                                    let grandGrandChildMenuItem = (ggci as NavbarMenuItem);
                                    this.setRestrictedMenuItemDisabled(grandGrandChildMenuItem);

                                    // Stop here - assume menu items does not go deeper
                                });
                            }
                        })
                    }
                })
            }
        })
    }

    private setRestrictedMenuItemDisabled(menuItem: NavbarMenuItem): void {
        // if (menuItem.validRoles && this.userRoleService.roles) {
        //     // If user has none of the valid roles set on the menu item, then disable the menu item
        //     if (!menuItem.validRoles.some(vr => this.userRoleService.roles.some(rs => rs.claimName === vr.toString() && rs.hasRole === true))) {
        //         menuItem['isDisabled'] = true;
        //     }
        // }
    }

    // protected login(): void {
    //     this.router.navigate(['/login']);
    // }

    protected logout(): void {
        this.socialMediaAuthService.logout();
    }

    protected getProfile(): void {
        this.identityService.getUserProfile().subscribe((profile) => {
            //this.profile = profile;
        })
    }

    protected getPhoto(): void {
        // if (!this.sessionService.currentSession.authenticatedUser?.base64Image) {
        //     this.identityService.getUserPhoto().subscribe((p) => {
        //         this.photo = `data:image/png;base64,${p}`;

        //         if (this.sessionService.currentSession.authenticatedUser) {
        //             this.sessionService.currentSession.authenticatedUser.base64Image = this.photo;
        //         }
        //     });
        // } else {
        //     this.photo = this.sessionService.currentSession.authenticatedUser.base64Image;
        // }
    }

    hasRequiredRoles(menuItem: NavbarMenuItem): boolean {
      const requiredRoles = menuItem?.requiredRoles;

      if (requiredRoles && requiredRoles.length > 0) {
        const roleFound = this.authService.userInfo?.claims?.some(x => requiredRoles?.some(y => y === x));
        return !!roleFound;
      }

      return true;
    }

    isAuthenticationEnabled(): boolean {
      return this.featureFlagService.isSocialMediaAuthenticationEnabled();
    }
}
