import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { MicrosoftUser } from "../models/microsoft-user.model";
import { UserInfo } from "../models/user-info.model";
import { AuthService } from "./auth-service";
import { IdentityService } from "./identity.service";
import { UserRoleService } from "./role-services";
import { SocialMediaAuthService } from "./social-media-auth.service";

type KikisosoEnvironmentName = 'local' | 'production';
export interface IKikisosoEnvironment {
  name: KikisosoEnvironmentName;
  production: boolean;
  rootApiUrl: string;
}

export class CurrentSession {
  environment?: IKikisosoEnvironment;
  isDevelopment!: boolean;
  identityLoadError?: string;
  isUserLoggedIn!: boolean;
  hasUserRoleClaims!: boolean;
  user?: MicrosoftUser;
}

@Injectable({
    providedIn: 'root'
  })
  export class SessionService {

    private webApiErrorResponseSubject = new BehaviorSubject<HttpErrorResponse>(new HttpErrorResponse({}));
    webApiErrorResponse$!: Observable<HttpErrorResponse>;
    currentSession: CurrentSession | undefined;
  isMobile: boolean;
  userInfo: UserInfo;

    constructor(public userRoleService: UserRoleService,
                private identityService: IdentityService,
                public breakpointObserver: BreakpointObserver,
                private socialMediaAuthService: SocialMediaAuthService,
                public authService: AuthService) {
    // this.currentSession = {
    //   environment: environment,
    //   isDevelopment: environment.production,
    //   isUserLoggedIn:
    // }

                  this.setSubscriptions();

    }

    private setSubscriptions(): void {
      this.setViewportBreakpointObserver();

      this.userRoleService.roleClaims$
            .subscribe(x => this.currentSession ? (this.currentSession.hasUserRoleClaims = x?.length > 0) : false);

      this.userRoleService.user$
              .subscribe(x => this.currentSession.user = x);

      this.authService.userInfo$
                .subscribe((userInfo) => {
                  if (userInfo?.isAuthenticated && !!this.userInfo) {
                    this.userInfo =  userInfo;
                  }
                });
      this.socialMediaAuthService.userInfo$
                .subscribe((userInfo) => {
                  if (userInfo?.isAuthenticated && !!this.userInfo) {
                    this.userInfo = userInfo;
                  }
                });
    }

    // getUserData() {
    //   this.userRoleService.getUserRoleClaims();
    //   this.identityService.getUserProfile();
    // }

    private setViewportBreakpointObserver(): void {
      this.breakpointObserver
      // .observe(['(min-width: 500px)'])
      .observe(['(max-width: 1366px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    }

}
