<!-- <div class="test" *ngIf="!loginDisplay">
  <p>Please sign-in to see your profile information.</p>
</div>

<div *ngIf="loginDisplay">
  <p>Login successful!</p>
  <p>Request your profile information by clicking Profile above.</p>
</div>

<div *ngIf="socialAuthService.authState | async as socialUser">
  <p>Hello, {{ socialUser.name }}!</p>
  <br />
  <p>Your email: {{ socialUser.email }}</p>
  <br />
  <button mat-raised-button (click)="logout()">Logout</button>
</div> -->

<!---Carousel Example -->
<div
  id="carouselExampleCaptions"
  class="carousel slide"
  data-bs-interval="false"
>
  <div class="carousel-indicators" >
    <!-- <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="0"
      class="active"
      aria-current="true"
      aria-label="Slide 1"
    ></button> -->
      <button *ngFor="let i of carouselItems;let idx = index"
        type="button"
        [ngClass]="{ active: idx == 0}"
        data-bs-target="#carouselExampleCaptions"
        [attr.data-bs-slide-to]="idx"
        [attr.aria-label]="idx+1"
      ></button>

       <!-- <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="0"
      class="active"
      aria-label="Slide 1"
    ></button>
     <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="1"
      aria-label="Slide 2"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="2"
      aria-label="Slide 3"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="3"
      aria-label="Slide 4"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to="4"
      aria-label="Slide 5"
    ></button> -->
  </div>
  <div class="carousel-inner">
    <div *ngFor="let item of carouselItems;index as idx" class="carousel-item" [ngClass]="{ active: idx == 0}">
      <img [src]="item.imgSrc" alt="..." role="button" (click)="navigateToArtwork()" />
      <div class="carousel-caption d-none d-md-block">
        <h5>{{item.title }}</h5>
        <p>{{item.description}}</p>
      </div>
    </div>
    <!-- <div class="carousel-item active">
      <img src="..\..\assets\welcomeImages\NyammeWelcome.jpg" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="..\..\assets\welcomeImages\BuddhaWelcome.jpg" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="..\..\assets\welcomeImages\GreenTaraWelcome.jpg" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="..\..\assets\welcomeImages\BigGreenTaraWelcome.jpg" alt="..." />
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img
        src="..\..\assets\welcomeImages\4ArmedChenrezigWelcome.jpg"
        alt="..."
      />
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div> -->
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="prev"
  >
    <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
    <mat-icon class="left-carousel-chevron">chevron_left</mat-icon>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="next"
  >
    <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
    <mat-icon class="right-carousel-chevron">chevron_right</mat-icon>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<h4 class="text-center mt-5" id="welcome-header">Welcome to Kikisoso</h4>
<p class="mt-2 text-center px-5" id="welcome-message">
  Explore the timeless beauty of Tibetan thangka art in our collection. Each piece captures the essence of spirituality, culture, and intricate workmanship. Whether you’re a seasoned collector, a practioner, or a curious soul, we invite you to immerse yourself in this sacred tradition.
</p>
<p class="mt-2 text-center px-5" id="welcome-message">
  Also, we invite you to browse our offerings of giclee prints that resonate with your spirit. Thank you for joining our artistic pilgrimage, and your patronage.
</p>
<p class="mt-2 text-center px-5" id="welcome-message">
  Tashi Delek!
</p>
