
<div class="logo-container" >
         <a routerLink="/">
          <img class="logo-img" src="./assets/logo.png" alt="Kikisoso">
        </a>
</div>
<ng-container *ngIf="!sessionService.isMobile">
<mat-toolbar class="mat-toolbar">
      <div class="navbar">
        <div class="navbar-menu-items">
            <span *ngFor="let item of navbarDefinition">
                <!-- Top-Level: No children -->
                <ng-container *ngIf="!item.children; else topMenuWithChildren">
                  <ng-container *ngIf="hasRequiredRoles(item)">
                    <a mat-menu-item [routerLink]="item.route" (mouseenter)="closeLastPopup(item)">
                      {{ item.title}}
                    </a>
                  </ng-container>
                </ng-container>

                <!-- Top-Level Has Children -->
                <ng-template #topMenuWithChildren>
                  <ng-container *ngIf="hasRequiredRoles(item)">
                    <a #button mat-menu-item [matMenuTriggerFor]="menu" #levelOneTrigger="matMenuTrigger" (mouseenter)="onMouseEnterOnRootMenuItem(item, levelOneTrigger)">
                       {{ item.title}} <mat-icon>arrow_drop_down</mat-icon>
                    </a>

                    <mat-menu #menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false">
                        <!-- Loop through sub-menu -->
                        <span (mouseleave)="onMouseLeaveOnPopupMenu(levelOneTrigger)">
                        <span *ngFor="let child of item.children">
                            <ng-container *ngIf="child?.type === 'divider'; else realMenuItem">
                                <mat-divider></mat-divider>
                            </ng-container>

                            <ng-template #realMenuItem>
                                <!-- Child-Level - No Children -->
                                <ng-container *ngIf="!child.children; else childWithChildren">
                                    <button mat-menu-item [routerLink]="child.route" [disabled]="child.isDisabled" (mouseenter)="setChildMenuOpenState(child)">
                                        <mat-icon>{{ child.icon }}</mat-icon>
                                        <span>{{ child.title }}</span>
                                    </button>
                                </ng-container>

                                <!-- Child-Level with Children -->
                                <ng-template #childWithChildren>
                                    <button mat-menu-item [matMenuTriggerFor]="sub_menu" #levelTwoTrigger="matMenuTrigger" [routerLink]="child.route" [disabled]="child.isDisabled" (mouseenter)="setChildMenuOpenState(child)">
                                        <mat-icon>{{ child.icon }}</mat-icon>
                                        <span>{{ child.title }}</span>
                                    </button>
                                    <mat-menu #sub_menu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false">
                                      <span (mousel)="onMouseLeaveChildPopupMenu(levelTwoTrigger)">
                                        <div *ngFor="let grandChild of child.children">
                                            <ng-container *ngIf="grandChild?.type === 'divider'; else realSubmenuItem">
                                                <mat-divider></mat-divider>
                                            </ng-container>

                                            <ng-template #realSubmenuItem>
                                                <!-- Assume no further child menus needed -->
                                                <button mat-menu-item [routerLink]="grandChild.route" [disabled]="grandChild.isDisabled">
                                                    <mat-icon>{{ grandChild.icon }}</mat-icon>
                                                    <span>{{ grandChild.title }}</span>
                                                </button>
                                            </ng-template>
                                        </div>
                                      </span>
                                    </mat-menu>
                                </ng-template>
                            </ng-template>
                          </span>
                        </span>
                    </mat-menu>

                  </ng-container>
                </ng-template>
              </span>
            </div>
        </div>

    <div class="user-profile-menu-container" *ngIf="isReady">
      <ng-container *ngIf="isUserLoggedIn; else showLoginButton">
        <div class="mt-3 mb-4 d-flex justify-content-center" *ngIf="isAuthenticationEnabled()">
          <button class="login-button" [matMenuTriggerFor]="loginmenu" mat-fab color="primary" aria-label="Example icon button with a home icon">
            <h4>{{ userInfo?.firstName[0] }}</h4>
          </button>

            <mat-menu class="mat-menu" #loginmenu="matMenu" xPosition="before" yPosition="below">
              <div style="margin: 15px">
                <div class="text-center">{{ userInfo?.fullName }}</div>
                <div class="text-center">{{ userInfo?.email }}</div>
                <!-- <span>isUserLoggedIn {{ isUserLoggedIn | json }}</span> -->
                <button class="mt-5 gold-text" mat-raised-button (click)="logoutUser()"><mat-icon>logout</mat-icon> Sign out</button>
              </div>
            </mat-menu>
        </div>
      </ng-container>
      <ng-template #showLoginButton>
        <div class="mt-4 mb-4 d-flex justify-content-center align-items-center" *ngIf="isAuthenticationEnabled()">
          <!-- <span>isUserLoggedIn {{ isUserLoggedIn | json }}</span> -->
            <button class="gold-text" mat-raised-button (click)="loginUser()"><mat-icon>login</mat-icon> Sign in</button>
        </div>
        <mat-divider></mat-divider>
      </ng-template>
    </div>

</mat-toolbar>
</ng-container>
<ng-container *ngIf="sessionService.isMobile">
  <div class="hamburger-menu">
    <button style="float: right" mat-icon-button (click)="onToggleSidenav()"><mat-icon>menu</mat-icon></button>
  </div>
</ng-container>
