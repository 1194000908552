<!-- <mat-toolbar color="primary">
  <a class="title" href="/">{{ title }}</a>

  <div class="toolbar-spacer"></div>

  <a mat-button [routerLink]="['profile']">Profile</a>

  <button mat-raised-button *ngIf="!authService.isUserLoggedIn" (click)="login()">Login</button>
  <button mat-raised-button *ngIf="authService.isUserLoggedIn" (click)="logout()">Logout</button>

</mat-toolbar>
<div class="container">


  <ng-container *ngIf="loading">
    <div class="spinner_overlay"></div>
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </ng-container>

  <router-outlet *ngIf="!isIframe"></router-outlet>
</div> -->

<!-- // new  -->

<app-layout>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <app-sidenav (sidenavClose)="sidenav.close()"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="wrapper">
        <app-navbar (sidenavToggle)="sidenav.toggle()"></app-navbar>
        <main>
          <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"></mat-progress-spinner>
          <!-- <ng-container *ngIf="loading">
            <div class="spinner_overlay"></div>
            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </ng-container> -->
            <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
          <router-outlet *ngIf="!isIframe"></router-outlet>
        </main>
        <footer class="footer container-fluid text-center footer-text">
            Copyright © {{year}} Kikisoso.com
            <div class="row text-center">
              <span>All Rights Reserved</span>
            </div>
            <br/>
            <span class="text-center footer-text">
                Developed by: Dhawa D Ngoche
            </span>
        </footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>

