import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthService } from '../services/auth-service';
import {
  ProviderName,
  SocialMediaAuthService,
} from '../services/social-media-auth.service';
import { UserInfo } from '../models/user-info.model';
import { LocalStorageService } from '../services/local-storage.service';
import { TwitterLoginService } from '../services/twitter-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  microsoftUser: UserInfo;
  socialMediaUser: UserInfo;

  constructor(
    private router: Router,
    public socialMediaAuthService: SocialMediaAuthService,
    // private location: Location,
    // public yahooLoginService: YahooLoginService,
    public twitterLoginService: TwitterLoginService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public socialAuthService: SocialAuthService,
  ) {}

  ngOnInit(): void {
    this.checkForAuthCodeFromProvider();
  }

  private checkForAuthCodeFromProvider(): void {
    const authProvider = this.localStorageService.getAuthSettings();

    if (authProvider?.providerName === 'Twitter') {
      this.activatedRoute.queryParams.subscribe((params) => {
        const authCode = params['code'];
        const state = params['state'];
        if (authCode) {
          this.localStorageService.save('code', authCode);
          this.twitterLoginService.getAccessToken(
            authCode,
            state
          );
        }
      });
    }
    // else if (authProvider?.providerName === 'Yahoo') {
    //   this.activatedRoute.queryParams.subscribe((params) => {
    //     const authCode = params['code'];
    //     const state = params['state'];
    //     if (authCode) {
    //       this.localStorageService.save('code', authCode);
    //       this.yahooLoginService.getAccessToken(
    //         authCode,
    //         state
    //       );
    //     }
    //   });
    // }
    else {
      this.setUserInfoSubscription();
    }
  }

  // redirectToPreviousPageIfUserIsAlreadyAuthenticated(): void {
  //   if (this.socialMedialLoginService.isUserLoggedIn || this.authService.isUserLoggedIn) {
  //     this.location.back();
  //   }
  // }

  setUserInfoSubscription(): void {
    this.socialMediaAuthService.userInfo$.subscribe({
      next: (data) => {
        this.socialMediaUser = data;
      },
    });

    this.authService.userInfo$.subscribe({
      next: (data) => {
        this.microsoftUser = data;
      },
    });
  }

  login(providerName: ProviderName): void {
    this.socialMediaAuthService.login(providerName);
  }
}
