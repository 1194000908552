<div class="page">
  <p class="text-center">
    If you have any questions, or feedback, feel free to reach out to us. We appreciate your patience as our response time may be slower than usual.
  </p>
  <p class="text-center">
    If you wish to place a custom-size print order, please email us with details. We will get back to you, and provide further instructions to process your order.
  </p>
  <p class="text-center">
    You can reach us at <span class="text-warning">kikisoso.com&#64;gmail.com</span>.
  </p>
  <p class="text-center">
    Thank you.
  </p>
</div>

