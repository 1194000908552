<mat-card class="card">
  <p class="title mt-3">Accolades</p>
  <p class="detail mt-1 pl-5 pr-5">
    The 28th of December, 1986, marked a significant milestone in my life—my
    graduation day. After a demanding nine-year journey mastering the Men-Nying
    thangka painting tradition, that day was the culmination of my dedication.
    The graduation ceremony was steeped in formality, reflecting the school’s
    establishment under His Holiness’s vision and its adherence to the strictest
    discipline. The day began with the thrilling prospect of an audience with
    His Holiness the Dalai Lama, followed by the formal proceedings at the
    Library of Tibetan Works and Archives (LTWA). We, the students, were honored
    with graduation certificates, heartfelt congratulations, and inspiring words
    of wisdom and encouragement.
  </p>
  <mat-card-header>
    <mat-card-title>Master of Thangka Painting Certificate</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="content-container">
      <img id="cert-photo" src="../../assets/my_thangka_cert.jpg" />
      <p></p>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="card">
  <p class="detail mt-5 pl-5 pr-5">
    From April 23, 1990 to July 10, 1994, I served as the resident thangka
    painter at the Sakya Monastery in Seattle, WA painting intricate murals
    inside the monastery. The scope of the project was daunting to say the
    least, but nothing could have been more perfect for a young thangka painter
    like myself. This was an opportunity of a lifetime, and an ultimate test of
    my artistic prowess. I was just 23 when I embarked on this multi-year
    endeavor.
  </p>
  <p class="detail">
    Throughout the project, I was fortunate to experience the kindness and
    patience of H.H. Jigdal Dagchen Sakya Rinpoche and the monastery’s members.
    They made me feel at home in Seattle and expressed deep appreciation for my
    work. Upon completing this extensive undertaking, I received an award—one
    that I humbly treasure. It stands as the most significant validation of my
    dedication and artistic efforts
  </p>
  <mat-card-header>
    <mat-card-title>Certificate of Award</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="content-container">
      <img id="cert-photo" src="../../assets/sakya_award.jpg" />
      <p></p>
    </div>
  </mat-card-content>
</mat-card>
