import { RoleClaimName } from "src/app/services/role-services";

export interface NavbarMenuItem {
    title: string,
    route?: string,
    type?: string,
    icon?: string,
    isDisabled?: boolean,
    children?: NavbarMenuItem[],
    requiredRoles?: RoleClaimName[]
}
export const navbarMainDefinition: NavbarMenuItem[] = [
    {
        title: 'Home',
        route: 'home',
        icon: 'home'

    },
    {
        title: 'Gallery',
        icon: 'color_lens',
        children: [
            {
                title: 'Paintings',
                route: 'artworks/painting',
                icon: 'brush'
            },
            {
                title: 'Sculpting',
                route: 'artworks/sculpting',
                icon: 'boy'
            },
        ]
    },
    {
      title: 'Prints',
      route: 'prints',
      icon: 'shop'
    },
    {
        title: 'Admin', requiredRoles: [RoleClaimName.Admin],
        route: 'admin',
        icon: 'admin_panel_settings'
        // requiredRoles: [RoleClaimName.Administrator],
        // children: [
        //     {
        //         title: 'Add New Post',
        //         route: 'newPost'
        //     },
        // ]
    },
    {
        title: 'About',
        // route: 'artist',
        icon: 'info',
        children: [
            {
              title: 'My Teacher',
              route: 'my-teacher',
              icon: 'person'
            },
            {
              title: 'My Bio',
              route: 'my-bio',
              icon: 'person'
            },
            {
              title: 'Certificate & Award',
              route: 'accolades',
              icon: 'stars'
          },
        ]
    },
    {
        title: 'Contact',
        route: 'contact',
        icon: 'quick_contacts_mail'
    },
]
