import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { IdentityService } from "./identity.service";
import { UserInfo } from "../models/user-info.model";
import { LocalStorageService } from "./local-storage.service";
import { UserRoleService } from "./role-services";
import { FeatureFlagService } from "./feature-flag.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _destroying$ = new Subject<void>();
  isUserLoggedIn: boolean = false;

  private userInfoSubject = new BehaviorSubject<UserInfo>(null);
  userInfo$ = this.userInfoSubject.asObservable();
  providerName: 'Microsoft';
  userInfo: UserInfo;

  isUserLoggedInSubject = new BehaviorSubject<boolean>(false);
  isUserLoggedIn$: Observable<boolean>;

  // userRolesUpdateSubject = new BehaviorSubject<string[]>(null);
  // userRolesUpdate$: Observable<string[]>;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private identityService: IdentityService,
              private localStorageService: LocalStorageService,
              // private roleService: UserRoleService,
              private featureFlagService: FeatureFlagService,
              private msalService: MsalService) {

    this.isUserLoggedIn$ = this.isUserLoggedInSubject.asObservable();
    // this.userRolesUpdate$ = this.userRolesUpdateSubject.asObservable();

    if (!this.featureFlagService.isSocialMediaAuthenticationEnabled()) {
      return;
    }


    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      /** when Msal pops up login dialog automatically (instead of user clicking on login button), need to set active account when user logs in successfully */
      const account = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(account);

      this.refreshLoginState();
    });

    this.providerName = 'Microsoft';
  }

  login():void {
    this.msalService.loginPopup()
      .subscribe({
        next: (result) => {
          this.msalService.instance.setActiveAccount(result.account);

          // this.parseUserInfo(result.account);

          // Call graph to get user profile
          // this.getUserProfile();
          this.refreshLoginState();
          // this.roleService.getUserRoleClaims()
        },
        error: (error) => console.log(error)
      })
    }

    // parseUserInfo(data: AccountInfo): void {

    //   const isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
    //   this.userInfo = new UserInfo(JSON.stringify(data.localAccountId),
    //                                 data.name,
    //                                 data.name.split(' ')[0],
    //                                 data.name.split(' ')[1],
    //                                 data.username,
    //                                 isAuthenticated,
    //                                 "Microsoft");

    //   this.notifyAuthenticationState(this.userInfo);

    // }

    getUserProfile(): void {
      if (!this.featureFlagService.isSocialMediaAuthenticationEnabled()) {
        return;
      }

      this.identityService.getUserProfile()
            .subscribe({
              next: (data) => {
                const isAuthenticated = this.getUserLoginState(); // this.msalService.instance.getAllAccounts().length > 0;
                this.userInfo = new UserInfo(JSON.stringify(data.id),
                                              data.displayName,
                                              data.givenName,
                                              data.surname,
                                              data.userPrincipalName,
                                              isAuthenticated,
                                              "Microsoft");

                this.notifyAuthenticationState(this.userInfo);
              },
              error: (err) => console.log(err),
              complete: () => {}
            })
    }

    logout(): void {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/'
      }).subscribe({
        next: (result) => {
          this.notifyAuthenticationState(this.getUnauthenticatedUserInfo());
        },
        error: (error) => console.log(error)
      });
    }

    notifyAuthenticationState(userInfo: UserInfo): void {
      if (!this.featureFlagService.isSocialMediaAuthenticationEnabled()) {
        return;
      }

      this.isUserLoggedIn = this.getUserLoginState();// this.msalService.instance.getAllAccounts().length > 0;
      if (this.isUserLoggedIn) {
        const hasExistingProvider = !!this.localStorageService.getAuthSettings()?.providerName;

        if (!hasExistingProvider) {
          this.localStorageService.save('providerName', this.providerName);
        }
      } else {
        this.localStorageService.removeAuthenticationProviderSettings();
      }
      this.userInfoSubject.next(userInfo);
    }

    private getUnauthenticatedUserInfo(): UserInfo {
      return new UserInfo(null,
        null,
        null,
        null,
        null,
        false,
        "Microsoft");
    }

    getUserLoginState(): boolean {
     if (!this.featureFlagService.isSocialMediaAuthenticationEnabled()) {
      return true;
     }
      return this.msalService.instance.getAllAccounts().length > 0;
    }

    refreshLoginState() {
      // const providerName = this.localStorageService.getAuthSettings()?.providerName;
      // if (providerName != 'Microsoft') {
      //   /* Do nothing, current provider is other than Microsoft */
      //   return;
      // }
      this.isUserLoggedIn = this.getUserLoginState(); // this.msalService.instance.getAllAccounts().length > 0; //!!this.msalService.instance.getActiveAccount();

      if (this.isUserLoggedIn) {
        this.getUserProfile();
      } else {
        this.notifyAuthenticationState(this.getUnauthenticatedUserInfo());
      }

      this.isUserLoggedInSubject.next(this.isUserLoggedIn);
    }

    updateUserClaims(claims: string[]): void {
      if (!this.featureFlagService.isSocialMediaAuthenticationEnabled()) {
        return;
      }

      if (this.userInfo) {
        this.userInfo.claims = claims;
      }
      this.userInfoSubject.next(this.userInfo);
    }
}
