import { EntityDataModuleConfig, EntityMetadataMap } from "@ngrx/data";
import { Customer } from "./models/customer.model";

export const entityMetadata: EntityMetadataMap = {
    /**
     * If primary key of your entity is not 'id', you have to specify the property
     */
    Customer: {
        selectId: (e: Customer) => e.customerId
    }
}

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata,
    pluralNames
}