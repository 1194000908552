<div class="container">
  <p class="title">About Me</p>
  <p class="detail">
    Tashi Delek! My name is Dhawa Dhondup Ngoche, a Tibetan thangka painter based in Redmond, WA USA. It's a pleasure to have you
    visit my site, and showcase my work. Welcome! Following is my bio that sheds light on my journey to become a thangka painter, my work
    and accomplishments.
    </p>
  <p class="title">Background</p>
  <p class="detail">
    My roots trace back to Dholanji, a secluded Tibetan refugee settlement
    nestled in the forested hills of Himachal Pradesh, India. This remote haven
    serves as the exile abode for Bon Menri Monastery. Amidst the rugged terrain
    and economic hardship, my parents, like other handful of Bonpo families,
    devoted themselves to erecting the monastery with extreme manual labor and
    dedication. Despite being forced into poverty and desperation in exile after
    China's brutal invasion of Tibet, my parents, like many Bonpo faimilies,
    found their way into a remote, uninhabited foreign land isolated and
    surrounded by hills. The women toiled, breaking down rocks into pebbles and
    sifting dirt, while the men wielded chisels and dig bars breaking down large
    boulders to lay the very foundation of our new spiritual sanctuary.
  </p>

  <p class="title">Early Interest</p>
  <p class="detail">
    From around 8 or 9 years of age, I developed a keen interest in sketching.
    My subjects ranged from Indian movie stars to cows and horses. Shapes and
    colors fascinated me, especially when it came to images of deities. I’d even
    study them while lying upside down! One memorable moment was when I drew a
    full image of a naked Buddha and painted it on a piece of paper. An old
    relative enshrined it in her tiny altar. Soon after, I received early
    training in sketching Buddha’s face, hands, and feet from Lhabso Namkha, the
    village thangka painter. I also learned to draw the Eight Auspicious Symbols
    such that at about age 10, I painted them on a prayer wheel that my parents
    commissioned with what little money they had. My mom, in her 80's, still
    turns the same prayer wheel.
  </p>

  <p class="title">Twist of Fate</p>
  <p class="detail">
    My karma had other plans for me as I graduated from the small settlement
    elementary school which had about 50-60 children attending. Several factors,
    especially my early interest in art, converged to alter the trajectory of my
    life. Instead of the high school admission I eagerly anticipated, I found
    myself admitted to a thangka painting school at the Library of Tibetan Works
    & Archives in Dharamsala when I was just 12 years old. Had our head master
    returned to school on time and granted me the elementary school completion
    credential, I would have embarked on a path of traditional education rather
    than pursuing thangka art. However, fate intervened. Thanks to my late
    father's quick thinking, encouragement from some of the village elders, and
    especially a recommendation letter from H.H. the Yongzin Tenzing Namdak
    Rinpoche (founder of Bon settlement and Menri Monastery in Dholanji, and
    Triten Norbutse Monastery in Katmandu, Nepal), I gained admission to the
    thangka painting school, forever changing the canvas of my life.
  </p>

  <p class="title">Training</p>
  <p class="detail">
    In the picturesque town of Dharamsala on July 7, 1989 during the notorious
    foggy season, I found myself—a young child from a settlement—struggling with
    homesickness after being admitted to the Thangka Painting School housed on
    the 3rd floor of the Library of Tibetan Works & Archives. I was just a boy
    among my classmates who were in their early twenties. We were like a small
    family sharing a dormitory, and the older students looked after and helped
    me transition. So, in the subsequent years, training 9-5, and six days a
    week, I achieved remarkable growth steadily honing my skills in thangka
    painting until I proudly stood as the valedictorian of my 1986 graduating
    class. My teacher Venerable Gen Sangye Yeshe, a graceful monk whom some
    visitors to Dharamsala mistook for H.H. the Dalai Lama, was personally
    appointed by His Holiness the Dalai Lama as our teacher. He imparted wisdom
    through authentic methods, drawing from his own experiences in Tibet. Our
    education transcended over other thangka painting schools that focused on
    producing thangkas for profit; it was about cultivating a deep understanding
    of thangkas and its spiritual implications. One notable cautionary advice
    from my teacher, which he received from his teacher, was that a thangka
    painter could go into either of two shortcuts after death depending on the
    quality of his work: 1) Heaven, if he/she paints with pure dedication and
    devotion; 2) Hell, if he/she is motivated by profit and produces substandard
    deity images. I strive for the former and as I take his advice to my heart
    due to the significance of thangkas in the world of Tibetan Buddhism and Bon
    religion. I extend utmost gratitude to my late teacher, His Holiness’s
    Private Office, and the late Director of LTWA Gyatso Tsering for providing
    for the one of a kind Thangka Painting school that has profoundly shaped my
    life.
  </p>

  <p class="title">My Work</p>
  <p class="detail">
    I’ve had the privilege of creating several high-profile paintings throughout
    my career. Notably, I was even offered a position in H.H.'s Private Office
    by H.H.'s secretary, Kungo Tara, after working there on multiple occasions.
    At the tender age of 17, I had the honor of painting a grand Four-Armed
    Avalokiteshvara for H.H.'s teacher, H.E. Ling Rinpoche. This was a seal of
    recognition for my developing skills. An elderly Tibetan couple visiting my
    school and seeing the painting bestowed upon me their most encouraging
    praise and heartfelt prayers for my artistic endeavors. Additionally, I had
    the opportunity to create the principal thangka (a traditional Tibetan
    Buddhist painting) for the Nechung Monastery, depicting Buddha and his two
    disciples. On our graduation day in 1986, we presented H.H. the Dalai Lama
    with a painting of Naljorma, a privilege I deeply cherish. I meticulously
    painted a highly detailed Amitabha heaven for Narita Temple, one of the
    sponsors of our school, in Japan. In the late 1980s, along with my talented
    friend Dorje Sangpo, I adorned the entry walls of Bon Menri Monastery with
    the depictions of the Four Guardian Kings. I have painted numerous other
    paintings, and in the Spring of 1990, I was invited to Seattle, WA by the
    Sakya Monastery to adorn the newly renovated monastery with murals depicting
    the Sakya Lamdre Lineage, the Wheel of Life, life sized Four Guardian Kings,
    and many other murals. I deeply cherish having the opportunity to have met
    the Sakya family, amazingly devoted members, and above all having the
    opportunity to work under the directions of the late H.H. the Jigdal Dagchen
    Sakya Rinpoche. This was an opportunity of a lifetime that allowed me to
    showcase my years of training and achieve significant growth as a young
    thangka painter undertaking a project that extended to 4 and half years to
    complete. It was most challenging and rewarding experience as I enjoyed each
    and every day. My murals at the Sakya Monastery are also depicted in the
    motion picture Little Buddha, directed by Bernardo Bertolucci. In 1995, the
    Seattle Arts Commission commissioned me to create a thangka titled
    “Awakening the Lotus Mind”, which showcased Green Tara against the backdrop
    of the Seattle skyline. At the pinnacle of this masterpiece stood
    Thousand-Armed Avalokiteshvara, Vajrapani, and Manjushri.
  </p>

  <p class="title">Looking Forward</p>
  <p class="detail">
    In recent years, I’ve embarked on experimenting with digital medium, using
    applications like Adobe Photoshop and Illustrator to create numerous
    thangkas. It opened a whole new realm of possibilities as I successfully
    painted several digital thangkas that I'm very proud of. I consider thangka
    painting to be my true calling and purpose in this lifetime. With every
    brushstroke, I pour my heart and soul into creating art that will stand as a
    proud legacy. My hope is to inspire the next generation of thangka painters
    as I explore and experiment thangka painting in the digital age, and leave a
    legacy that my teacher would be proud of.
  </p>
  <p class="detail">Thank you for reading this far. Cheers!</p>
</div>
