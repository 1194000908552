import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-common";

import { AuthService } from './auth-service';
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { EMPTY, firstValueFrom, from, Observable, of } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { environment } from "src/environments/environment";
import { UserRoleService } from "./role-services";
import { SilentRequest } from "@azure/msal-browser/dist/request/SilentRequest";
import { GoogleLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
import { ProviderNames } from "./social-media-auth.service";

@Injectable({
  providedIn: 'root'
})
export class SocialMediaAuthInterceptorService implements HttpInterceptor {
  activeAccount: AccountInfo | null;

  constructor(private _router: Router, private socialAuthService: SocialAuthService, private authService: AuthService,
    private loadingIndicatorService: LoadingIndicatorService, private userRoleService: UserRoleService) {
    // this.activeAccount = this.socialAuthService.authState.forEach.instance.getActiveAccount();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientTokenRequestKey:string = 'client';
    const webApiTokenRequestKey:string = 'webApi';
    const graphApiTokenRequestKey:string = 'graphApi';

    if (this.authService.isUserLoggedIn && this.authService.providerName === ProviderNames.Microsoft) {
      return next.handle(req);
    }
    this.loadingIndicatorService.show();

    // if (!this.activeAccount || !(req.url.startsWith(environment.rootApiUrl) || req.url.startsWith(environment.graphBaseUri))) {
    //   const authReq = req.clone({ withCredentials: true });
    //   return next.handle(authReq);
    // }

    // const tokenRequests = {
    //   [clientTokenRequestKey]: {
    //     account: this.activeAccount,
    //     scopes: [...environment.oAuthSettings.scopes] // must pass at least user.read
    //   },
    //   [webApiTokenRequestKey]: {
    //     account: this.activeAccount,
    //     scopes: [...environment.apiScopes] // ["api://.....""]]
    //   },
    //   [graphApiTokenRequestKey]: {
    //     account: this.activeAccount,
    //     scopes: [...environment.oAuthSettings.scopes] // must pass at least user.read
    //   }
    // }

    // let tokenRequestKey: string;

    // if (req.url.startsWith(environment.rootApiUrl)) {
    //   tokenRequestKey = webApiTokenRequestKey;
    // } else if (req.url.startsWith(environment.graphBaseUri)) {
    //   tokenRequestKey = graphApiTokenRequestKey;
    // } else {
    //   tokenRequestKey = clientTokenRequestKey;
    // }
// alert('singo');
console.log(req.url);

// this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(data => {
//   const accessToken = data;

//   alert('dingo');
//   const headers = {
//     headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
//   };

//   const authReq = req.clone({ ...headers });

//   return firstValueFrom(next.handle(authReq).pipe(tap(_ => {}, error => {
//     var respError = error as HttpErrorResponse;
//     if (respError && (respError.status === 401 || respError.status === 403)) {
//       this._router.navigate(['/unauthorized']);
//     }
//   }), finalize(() => {
//     alert('wooodooo');
//     this.loadingIndicatorService.hide();
//   })));
// })
// .catch(error => console.log("HHHHHH:", error));

    return from(
      this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(data => {
        const accessToken = data;
        console.log("Access Token: ", accessToken);

        // alert('dingo');
        const headers = {
          headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
        };

        const authReq = req.clone({ ...headers });

        // return firstValueFrom(next.handle(authReq)
        // .pipe(tap(_ => {},
        //   error => {
        //   var respError = error as HttpErrorResponse;

        //   if (respError && (respError.status === 401 || respError.status === 403)) {
        //     this._router.navigate(['/unauthorized']);
        //   }
        // }), finalize(() => {
        //   alert('wooodooo');
        //   this.loadingIndicatorService.hide();
        // })));

        // return next.handle(authReq).toPromise();

        // return firstValueFrom(next.handle(authReq));
        return firstValueFrom(next.handle(authReq)
        .pipe(catchError(err => {
          var respError = err as HttpErrorResponse;
          alert('func')

          if (respError && (respError.status === 401 || respError.status === 403)) {
            this._router.navigate(['/unauthorized']);
          }
          return of(err);
        }), finalize(() => {
          alert('wooodooo');
          this.loadingIndicatorService.hide();
        })));
      })
      )
    }
}

