import { Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginComponent } from 'src/app/login/login.component';
import { ProfileType } from 'src/app/profile/profile.component';
import { AuthService } from 'src/app/services/auth-service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserRoleService } from 'src/app/services/role-services';
import { SessionService } from 'src/app/services/session.service';
import { SocialMediaAuthService } from 'src/app/services/social-media-auth.service';
import { navbarMainDefinition } from './definitions/main.definition';
import { NavbarBaseComponent } from './navbar-base.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends NavbarBaseComponent implements OnInit, OnDestroy {
  @Output() sidenavClose = new EventEmitter();
  @Output() sidenavToggle = new EventEmitter();
  navbarDefinition = navbarMainDefinition;
  profile!: ProfileType;
  photo: string = '';
  hasWebApiError: Boolean = false;
  currentUrl: string;

  currentUser: any;

  constructor(
    public authService: AuthService,
    protected userRoleService: UserRoleService,
    protected identityService: IdentityService,
    protected sessionService: SessionService,
    private ren: Renderer2,
    public dialog: MatDialog,
    protected router: Router,
    protected socialMediaAuthService: SocialMediaAuthService,
    protected featureFlagService: FeatureFlagService
  ) {
    super(authService, userRoleService, identityService, sessionService, router, socialMediaAuthService, featureFlagService);
    this.initSubscriptions();
  }

  ngOnInit(): void {
    // this.initSubscriptions();
    this.currentUrl = window.location.href;
  }

  ngOnDestroy(): void {
    this.removeSubscriptions();
  }

  onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  onToggleSidenav(): void {
    this.sidenavToggle.emit(true);
  }

  loginUser(): void {
    // this.login();
    this.openDialog();
  }

  logoutUser(): void {
    this.logout();
  }


  /* Code to handle show/close popup menu */
  private currentMenuTitle: string = '';
  private lastTrigger: any;
  private isChildMenuOpen: boolean = false;

  onMouseEnterOnRootMenuItem(rootMenuItem: any, trigger: MatMenuTrigger): void {
    if (this.lastTrigger) {
      this.lastTrigger.closeMenu();
    }
    trigger.openMenu();
    this.currentMenuTitle = rootMenuItem.title;
    this.lastTrigger = trigger;
  }

  closeLastPopup(rootMenuItem: any): void {
    if (!rootMenuItem.children) {
      this.lastTrigger?.closeMenu();
    }
  }

  onMouseLeaveOnPopupMenu(trigger: MatMenuTrigger): void {
    if (!this.isChildMenuOpen) {
      trigger.closeMenu();
    }
  }

  onMouseLeaveChildPopupMenu(trigger: MatMenuTrigger) : void {
    trigger.closeMenu();
  }

  setChildMenuOpenState(menuItem: any): void {
    this.isChildMenuOpen = !!menuItem.children;
  }
  /* Popup menu end */

  openDialog(): void {
    this.matDialogRef = this.dialog.open(LoginComponent, {
      data: {
        // animal: 'panda',
      },
      width: '400px'
    });
  }
}
