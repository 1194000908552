import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
    providedIn: 'root'
})
export class IdentityService {
    serviceName: string = 'IdentityService';

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getUserPhoto(): Observable<string> {
        const url = 'https://graph.microsoft.com/v1.0/me/photo/$value';
        return this.httpClient.get(url, { responseType: 'arraybuffer'})
                .pipe(map(x => Buffer.from(x).toString()))
    }

    getUserProfile(): Observable<any> {
        return this.httpClient.get(GRAPH_ENDPOINT);
    }
}