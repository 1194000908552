import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { filter } from 'rxjs/operators';
import { CarouselImageModel } from '../models/carousel.image.model';
import { MicrosoftUser } from '../models/microsoft-user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  pageTitle : string = 'Welcome To Kikisoso';
  loginDisplay = false;

  welcomeActiveImage: string = '';
  welcomeImages: CarouselImageModel[] = [];
  user: MicrosoftUser;
  carouselItems = [
    {
      'imgSrc':`..\\..\\assets\\welcomeImages\\NyammeWelcome.jpg`,
      'title': 'Je Nyamme Sherab Gyaltsen',
      'description': 'Je Nyamme Sherab Gyaltsen (1356-1415): founder of Bon Menri Monastery in 1405'
    },
    {
      'imgSrc':`..\\..\\assets\\welcomeImages\\BuddhaWelcome.jpg`,
      'title': 'Shakyamuni Buddha',
      'description': 'Buddha Shakyamuni (563 BCE to 483 BCE), also known as Siddhartha Gautama, is the founder of Buddhism.'
    },
    {
      'imgSrc':`..\\..\\assets\\welcomeImages\\GreenTaraWelcome.jpg`,
      'title': 'Jetsun Dolma (Green Tara)',
      'description': 'Green Tara represents unconditional love and kindness, emphasizing emphathy and altruism. She is the archetypal embodiment of the enlightened essence of mind, the qualities of mother nature, protection, and nurturing courage'
    },
    {
      'imgSrc':`..\\..\\assets\\welcomeImages\\BigGreenTaraWelcome.jpg`,
      'title': 'Jetsun Dolma (Green Tara)',
      'description': 'Green Tara represents unconditional love and kindness, emphasizing emphathy and altruism. She is the archetypal embodiment of the enlightened essence of mind, the qualities of mother nature, protection, and nurturing courage'
    },
    {
      'imgSrc':'..\\..\\assets\\welcomeImages\\4ArmedChenrezigWelcome.jpg',
      'title': 'Four Armed Chenrezig',
      'description': 'The Four-Armed Avalokiteshvara, also known as "Chenrezig", embodies boundless compassion and wisdom'
    },
    {
      'imgSrc':'..\\..\\assets\\welcomeImages\\ChamaWelcome.jpg',
      'title': 'Yumchen Sherab Jamma',
      'description': 'Sherab Chamma is the Mother of all Enlightened Ones and the source of all love and compassion'
    },
    {
      'imgSrc':'..\\..\\assets\\welcomeImages\\MenriTrizinWelcome.jpg',
      'title': 'H.H. the Great 33rd Abbot',
      'description': 'H.H. the Great 33rd Abbot of Bon Menri Monastery in Dholanji, H.P. India'
    }
  ];

  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    public socialAuthService: SocialAuthService) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout(): void {
    this.socialAuthService.signOut().then(() => this.router.navigate(['login']));
  }

  navigateToArtwork() : void {
    this.router.navigateByUrl('/artworks/painting');
  }
}
