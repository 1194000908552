import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtworkModel } from '../../models/artwork.model';
// import { GicleePrintModel } from '../../models/products/gicleeprint.model';
import { ArtworkService } from "../../services/artwork.service";

// import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ArtworkDetailComponent } from 'src/app/artwork/artwork-detail.component';

// import { PaypalCodesComponent } from '../paypal/paypalcodes.component';
// import { PaypalCodesModel } from '../../models/paypal/paypalcodes.model';
// import { SanitizeHtml } from '../../pipes/sanitize-html.pipe';


@Component({
    selector: "shop-component",
    templateUrl: "./shop.component.html",
    styleUrls: ["./shop.component.css"]
})

export class ShopComponent implements OnInit {

  //  paypalForm: string;

  //   paypalFieldCmd: string;
  //   paypalFieldHostedButtonId: string;
  //   paypalFieldOn0: string;
  //   paypalFieldOn1: string;
  //   paypalFieldCurrencyCode: string;
  //   paypalCodesComponent : PaypalCodesComponent = new PaypalCodesComponent();

    constructor(private _route: ActivatedRoute,
                private _artworkService : ArtworkService,
                public dialog: MatDialog,
                private route: ActivatedRoute) {  }

    artworkId: number;
    artworkTitle : string;
    artworks : ArtworkModel[];
    imageUrl:  string;
    artwork: ArtworkModel;
    errorMessage: any;
    // buyProduct: GicleePrintModel;
    // @Output() selectedArtworkTitle : string;
    // category : string;
    // subCategory : string;

    // products = [
    //     {"Id": 1, "ProductCategory": "Giclee Print", "ProductName": "Shakyamuni Buddha", "PaypalButtonId": "DW6V2DZ77ZBNG"},
    //     {"Id": 2, "ProductCategory": "Giclee Print", "ProductName": "Green Tara With Avalokiteshvara", "PaypalButtonId": "LKNMTQKNL9PKQ"},
    //     // NOTE: Following 3 have fake Paypal Ids
    //     {"Id": 3, "ProductCategory": "Giclee Print", "ProductName": "Four Armed Chenrezig", "PaypalButtonId": "xxHVXHY874S3G9C"},
    //     {"Id": 4, "ProductCategory": "Giclee Print", "ProductName": "Nyamme Sherab Gyaltsen", "PaypalButtonId": "yyHVXHY874S3G9C"},
    //     {"Id": 5, "ProductCategory": "Giclee Print", "ProductName": "Green Tara", "PaypalButtonId": "ddHVXHY874S3G9C"}
    // ];


    ngOnInit(): void {
      this.initSubscriptions();
      // alert('shoppi')
        this._route.params.subscribe(params => this.artworkId = <any> params['itemId']);
        // this._route.params.subscribe(params => this.artworkTitle = <any> params['itemTitle']);
        // this._route.params.subscribe(params => this.imageUrl = <any> params['imageUrl']);
        this.getTestData();
        // this.category = this.artwork.category;
        // this.subCategory = this.artwork.subcategory;

        // let products = <GicleePrintModel[]>this.products
        //                         .filter(p => p.Id == this.artworkId && p.ProductName == this.artworkTitle);

        // this.buyProduct = new GicleePrintModel(
        //     products[0].Id,
        //     products[0].ProductCategory,
        //     products[0].ProductName,
        //     products[0].PaypalButtonId
        // );

        // let paypalCode = <PaypalCodesModel>this.paypalCodesComponent.getPaypalCodes()
        //                     .filter((c) => c.HashId == this.buyProduct.PaypalButtonId)[0];
        // if (paypalCode !== undefined) {
        //      //alert(paypalCode.RawHtml);
        //         this.paypalForm = paypalCode.RawHtml;
        // };

                // Get the product
                //this.getTestData();
                //this.getArtwork();
        }

        initSubscriptions(): void {
          this._artworkService.closeArtworkDetailModal$
                  .subscribe(x => {
                    if (!!x) {
                      this.dialog.closeAll();
                    }
                  })
        }

        getArtworkDetail() : void {
            // let artworkImage = event.srcElement.getElementsByClassName('artworkImage')[0];
            // this.imageUrl = this.artwork.imageUrl;
            // this.selectedArtworkTitle = this.artwork.title;

            //
            // Notify artworkService to emit event
            //
            // this._artworkService.emitCurrentArtwork(this.artwork);

            const dialogRef = this.dialog.open(ArtworkDetailComponent, {
              data: { artwork: this.artwork }
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog result: ${result}`);
            });
        }

        getTestData() : void {
                    this.artwork = (<ArtworkModel[]> this._artworkService.getJsonTestFile())
                            .find((item) => item.artworkId == this.artworkId);
            console.log(this.artwork);
        }
    }
