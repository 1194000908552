import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, of, throwError } from 'rxjs';
import { ArtworkModel } from '../models/artwork.model';
import { catchError, filter, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ArtworkService {

    private _artworkUrl: string = "foo";
    private _artworksUrl : string = "foofoofoo";

    private closeModalSubject: Subject<boolean> = new Subject<boolean>();
    closeArtworkDetailModal$: Observable<boolean> = this.closeModalSubject.asObservable();

    // selectedArtwork: ArtworkModel;
    // public artworkSelectedEmitter$: EventEmitter<ArtworkModel>;

    constructor(private _http: HttpClient) {
        // this.artworkSelectedEmitter$ = new EventEmitter<ArtworkModel>();
    } // This is the injection of Http client instance.

    closeArtworkDetailModal(): void {
      this.closeModalSubject.next(true);
    }

    getArtworks(): Observable<ArtworkModel[]> {

        // return this._http.get<ArtworkModel[]>(this._artworksUrl);
        return of(this.getJsonTestFile() as ArtworkModel[]);
    }

    getArtwork(artworkId: number, artworkTitle: string): Observable<ArtworkModel> { // Returns Observable of IArtwork[]
        return this._http.get<ArtworkModel>(this._artworkUrl);
    }

    // emitCurrentArtwork(art: ArtworkModel): void {
    //     this.artworkSelectedEmitter$.emit(art);
    // }

    private handleError(error: Response) {
        console.error(error);
        return throwError(error || 'server error');
    }

    getJsonTestFile() : any {
        return [
                {
                    "artworkId": 1,
                    "category": "artworks",
                    "subcategory": "painting",
                    "title": "Shakyamuni Buddha",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "Acrylic on Fine Canvas",
                    "imageUrl": './assets/thangkas/Buddha/Buddha.jpg',
                    "subImages": [{ "imageUrl": './assets/thangkas/Buddha/1_Buddha_1.jpg'}, {"imageUrl": './assets/thangkas/Buddha/1_Buddha_2.jpg'}, {"imageUrl": './assets/thangkas/Buddha/1_Buddha_3.jpg'}],
                    "description": "This painting is of enlightened Shakyamuni Buddha",
                    "significance": "Buddha is the enlightened teacher who has shown us the path of liberation from the existential suffering called Samsara.",
                    "courtesy": "David T. Newcomb",
                    "createDate": "July 7, 1998",
                    "mantra": "Om Mani Padme Hung",
                    "subjectHyperlinks": ["https://en.wikipedia.org/wiki/Gautama_Buddha"]
                },
                {
                    "artworkId": 2,
                    "category": "artworks",
                    "subcategory": "painting",
                    "title": "Green Tara With Avalokiteshvara",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "Acrylic on Fine Canvas",
                    "imageUrl": './assets/thangkas/BigTara/GreenTaraBig.jpg',
                    "subImages": [{ "imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_1.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_2.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_3.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_4.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_5.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_6.jpg'}, {"imageUrl": './assets/thangkas/BigTara/2_GreenTaraBig_7.jpg'}],
                    "description": "This painting is entitled 'Awakening the Lotus Mind'. Above the Seattle skyline, it depicts Green Tara (main), Avalokiteshvara (top middle), Manjushiri (top left), and Vajrapani(top right).",
                    "significance": "This painting was commissioned by Seattle Arts Commission in 1995.",
                    "courtesy": "Seattle Arts Commission",
                    "createDate": "December 27, 2005",
                    "mantra": "Om Ta Re Tu Ta Re Tu Re So Ha",
                    "subjectHyperlinks": ["https://en.wikipedia.org/wiki/Tara_(Buddhism)"]
                },
                {
                    "artworkId": 3,
                    "category": "artworks",
                    "subcategory": "painting",
                    "title": "Four Armed Chenrezig",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "Acrylic on Fine Canvas",
                    "imageUrl": './assets/thangkas/4ArmedChenrezig/4ArmedChenrezig.jpg',
                    "subImages": [{ "imageUrl": './assets/thangkas/4ArmedChenrezig/3_4ArmedChenrezig_1.jpg'}, {"imageUrl": './assets/thangkas/4ArmedChenrezig/3_4ArmedChenrezig_2.jpg'}, {"imageUrl": './assets/thangkas/4ArmedChenrezig/3_4ArmedChenrezig_3.jpg'}],
                    "description": "This painting is of the Four Armed Avalokiteshvara (Chenrezig). It was commissioned by the Burke Museum.",
                    "significance": "Avalokiteshvar is the deity of compassion.",
                    "courtesy": "Burke Museum (Seattle, WA)",
                    "createDate": "May 22, 2005",
                    "mantra": "Om Mani Padme Hung",
                    "subjectHyperlinks": ["https://en.wikipedia.org/wiki/Avalokite%C5%9Bvara"]
                },
                {
                    "artworkId": 4,
                    "category": "artworks",
                    "subcategory": "painting",
                    "title": "Nyamme Sherab Gyaltsen",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "Software Applications",
                    "imageUrl": './assets/thangkas/Nyamme/Nyamme.png',
                    "subImages": [{ "imageUrl": './assets/thangkas/Nyamme/4_Nyamme_1.jpg'}, {"imageUrl": './assets/thangkas/Nyamme/4_Nyamme_2.jpg'}, {"imageUrl": './assets/thangkas/Nyamme/4_Nyamme_3.jpg'}],
                    "description": "This painting is of 14th century Bon master Nyamme Sherab Gyaltsen.",
                    "significance": "He is regarded as one of the most prolific, and most attained Bon master in Bon history.",
                    "courtesy": "Dhawa D Ngoche",
                    "createDate": "October 13, 2007",
                    "mantra": "Om Mati Mu Ye Sa Le Du",
                    "subjectHyperlinks": ["http://www.himalayanart.org/search/set.cfm?setID=313"]
                },
                {
                    "artworkId": 5,
                    "category": "artworks",
                    "subcategory": "painting",
                    "title": "Green Tara",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "Acrylic on Fine Canvas",
                    "imageUrl": './assets/thangkas/GreenTara/GreenTara.jpg',
                    "subImages": [{ "imageUrl": './assets/thangkas/GreenTara/5_GreenTara_1.jpg'},
                    {"imageUrl": './assets/thangkas/GreenTara/5_GreenTara_2.jpg'},
                    {"imageUrl": './assets/thangkas/GreenTara/5_GreenTara_3.jpg'},
                    {"imageUrl": './assets/thangkas/GreenTara/5_GreenTara_4.jpg'}],
                    "description": "This painting of Green Tara.",
                    "significance": "Green Tara is regarded as the deity of compassion in female form. Her immense compassion is to all sentient beings, and to liberate us from the existential suffering called Samsara",
                    "courtesy": "Green Tara Incorporated (Lynnwood, WA)",
                    "createDate": "December 20, 1999",
                    "mantra": "Om Ta Re Tu Ta Re Tu Re So Ha",
                    "subjectHyperlinks": ["https://en.wikipedia.org/wiki/Tara_(Buddhism)"]
                },
                {
                  "artworkId": 6,
                  "category": "artworks",
                  "subcategory": "painting",
                  "title": "H.H. the 33rd Menri Abbot",
                  "artistName": "Dhawa D Ngoche",
                  "medium": "Digital",
                  "imageUrl": './assets/thangkas/MenriTrizin/MenriTrizin.png',
                  "subImages": [{ "imageUrl": './assets/thangkas/MenriTrizin/6_MenriTrizin_1.jpg'},
                  {"imageUrl": './assets/thangkas/MenriTrizin/6_MenriTrizin_2.jpg'},
                  {"imageUrl": './assets/thangkas/MenriTrizin/6_MenriTrizin_3.jpg'},
                  {"imageUrl": './assets/thangkas/MenriTrizin/6_MenriTrizin_4.jpg'},
                  {"imageUrl": './assets/thangkas/MenriTrizin/6_MenriTrizin_5.jpg'}],
                  "description": "This painting of H.H. the late 33rd Abbot of Bon Menri Monastery.",
                  "significance": "H.H. the late 33rd Abbot of Bon Menri Monastery",
                  "courtesy": "Dhawa D Ngoche",
                  "createDate": "Feb 10, 2022",
                  "mantra": "Om Ma Ti Mu Ye So Ha",
                  "subjectHyperlinks": ["https://www.olmoling.org/contents/his_holiness_33rd_abbot_of_menri/"]
              },
              {
                "artworkId": 7,
                "category": "artworks",
                "subcategory": "painting",
                "title": "Yumchen Sherab Chamma",
                "artistName": "Dhawa D Ngoche",
                "medium": "Digital",
                "imageUrl": './assets/thangkas/SherabChama/SherabChama.jpg',
                "subImages": [{ "imageUrl": './assets/thangkas/SherabChama/7_SherabChama_1.jpg'},
                {"imageUrl": './assets/thangkas/SherabChama/7_SherabChama_2.jpg'},
                {"imageUrl": './assets/thangkas/SherabChama/7_SherabChama_3.jpg'},
                {"imageUrl": './assets/thangkas/SherabChama/7_SherabChama_4.jpg'}],
                "description": "This painting of Bon deity Yumchen Sherab Chama.",
                "significance": "Sherab Chamma, loving mother of wisdom",
                "courtesy": "Dhawa D Ngoche",
                "createDate": "Feb 10, 2022",
                "mantra": "Om Ma Wa Ma De Ma He Mo Ha, Ema Ho Ma Ye Ru Pa Ye Ta Du Du So Ha",
                "subjectHyperlinks":[ "https://www.himalayanart.org/search/set.cfm?setID=356"]
            },
                {
                    "artworkId": 8,
                    "category": "artworks",
                    "subcategory": "sculpting",
                    "title": "Shakyamuni Buddha",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "3d Modelling",
                    "imageUrl": './assets/sculptures/sculpture1.jpg',
                    "subImages": [{"imageUrl": './assets/sculptures/sculpture3.jpg'}],
                    "description": "3d model of Shakyamuni Buddha's head",
                    "significance": "Buddha is the enlightened teacher who has shown us the path of liberation from the existential suffering called Samsara",
                    "courtesy": "Dhawa D Ngoche",
                    "createDate": "July 9, 2017",
                    "mantra": "Om Mani Padme Hung",
                    "subjectHyperlinks": []
                },
                {
                    "artworkId": 9,
                    "category": "artworks",
                    "subcategory": "sculpting",
                    "title": "Shakyamuni Buddha",
                    "artistName": "Dhawa D Ngoche",
                    "medium": "3d Modelling",
                    "imageUrl": './assets/sculptures/sculpture2.jpg',
                    "subImages": [{"imageUrl": './assets/sculptures/sculpture3.jpg'}],
                    "description": "3d model of Buddha's head.",
                    "significance": "3d model of Shakyamuni Buddha's head.",
                    "courtesy": "Dhawa D Ngoche",
                    "createDate": "July 9, 2017",
                    "mantra": "Om Mani Padme Hung",
                    "subjectHyperlinks": []
                }
            ]
    }
}
