import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  imageUrl: string;
  title: string
}

@Component({
    selector: 'imageViewer',
    templateUrl: './imageViewer.component.html',
    styleUrls: ['./imageViewer.component.css']
})

export class ImageViewerComponent {
    // @Input() currentImageUrl : string;
    // @Input() currentImageTitle : string;

    constructor(@Inject(MAT_DIALOG_DATA) public artwork: DialogData) {}

    // onCurrentImageUrl(imageUrl: string) : void {
    //     console.log("Glaaah");
    // }

    // onCurrentImageTitle(title : string) : void {
    //     console.log("Blaaah");
    // }

    // testing() : void {
    //     console.log(this.artwork.imageUrl);
    //     console.log(this.artwork.title);
    // }
}
