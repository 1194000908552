<span class="cards-container" >
  <mat-card appearance="outlined" class="card" *ngFor='let artwork of artworks' role="button" (click)="getArtwork(artwork.artworkId)">
    <mat-card-content>
      <div class="row">

        <span class="column1"><img class="artworkImage opaque" [src]='artwork.imageUrl' [title]='artwork.title'></span>
        <span class="column2">
          <div><span class="gold-text">Title: </span><span class="artInfo">{{ artwork.title }}</span></div>
          <div><span class="gold-text">Description: </span><span class="artInfo">{{ artwork.description }}</span></div>
          <div><span class="gold-text">Artist: </span><span class="artInfo">{{ artwork.artistName }}</span></div>
          <div><span class="gold-text">CreateDate: </span><span class="artInfo">{{ artwork.createDate }}</span></div>
          <div><span class="gold-text">Mantra: </span><span class="artInfo">{{ artwork.mantra }}</span></div>
          <span class="clickForDetails" style="display:flex;justify-content: center"><mat-icon>touch_app</mat-icon>Details</span>

          <!-- <span class="buy-button-container">
            <button class="btn btn-outline-primary btn" id="btnBuy" (click)="buy()" type="button" data-dismiss="modal">Buy Giclee Prints</button>
          </span> -->
        </span>
      </div>
    </mat-card-content>
  </mat-card>

</span>
