import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  showStdSuccessToast(operationName: string) {

  }

  showStdErrorToast(operationName: string, error: Error | undefined) {

  }
}
