/* This class is used to standardize user info after login
    so that the properties can be uniformly accessed
    regardless of which provider authenticated the user.
    Each provider expose different properties about user */
export class UserInfo {
	constructor(public userId: string, // guid
			public fullName: string, // displayName
			public firstName: string, // givenName
			public lastName: string, // surname
			public email: string,  // userPrincipalName
      public isAuthenticated: boolean,
      public authenticationAuthority: string,
      public claims?: string[]){}
}
