import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from 'src/app/login/login.component';
import { ProfileType } from 'src/app/profile/profile.component';
import { AuthService } from 'src/app/services/auth-service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserRoleService } from 'src/app/services/role-services';
import { SessionService } from 'src/app/services/session.service';
import { SocialMediaAuthService } from 'src/app/services/social-media-auth.service';
import { navbarMainDefinition } from '../definitions/main.definition';

import { NavbarBaseComponent } from '../navbar-base.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends NavbarBaseComponent implements OnInit, OnDestroy {
  @Output() sidenavClose = new EventEmitter();
  profile!: ProfileType;
  navbarDefinition = navbarMainDefinition;
  photo: string = '';

  constructor(
    public authService: AuthService,
    protected userRoleService: UserRoleService,
    protected identityService: IdentityService,
    protected sessionService: SessionService,
    protected router: Router,
    public dialog: MatDialog,
    protected socialMediaAuthService: SocialMediaAuthService,
    protected featureFlagService: FeatureFlagService
    ) {
      super(authService, userRoleService, identityService, sessionService, router, socialMediaAuthService, featureFlagService);
    }
  ngOnInit(): void {
    if (this.sessionService.isMobile) {
      this.initSubscriptions();
    }
  }

  ngOnDestroy(): void {
      this.removeSubscriptions();
  }

  onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  loginUser(): void {
    // this.router.navigate(['/login']);
    this.openDialog();
    this.sidenavClose.emit();
  }

  logoutUser(): void {
    this.logout();
  }

  openDialog(): void {
    this.matDialogRef = this.dialog.open(LoginComponent, {
      data: {
        // animal: 'panda',
      },
    });
  }
}
