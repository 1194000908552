import { Component } from "@angular/core";

@Component({
  selector: 'app-my-teacher',
  templateUrl: './my-teacher.component.html',
  styleUrl: './my-teacher.component.scss'
})
export class MyTeacherComponent {

}
