
<div class="page">
  <h5 class="title my-3">Giclee Prints</h5>
  <div class="fine-prints">
    <p class="text-center">Giclee prints are printed using museum grade pigmented ink on acid free (archival) fine art canvas. They come as scroll only with 2 inch white border for handling. Each artwork print is signed by the artist as limited edition. We can also accomodate custom size orders upto 60 inches in width. Please visit the contact page for details.</p>
    <p class="text-center"><span class="text-warning">Disclaimer: </span>Please note that the size dimensions of the prints are approximate (based on width) as the proportions of the paintings are not uniform.</p>
  </div>
  <span class="text-center">Also, please review our <a href="/return-policy">Return Policy</a> before making a purchase!</span>

  <product-list [artworkId]="artworkId"></product-list>

</div>


<div>
  <!-- <img id="buyProductImage" src="{{artwork.imageUrl}}" role="button" (click)="getArtworkDetail()" data-target="#artworkDetails" data-toggle="modal"/>
  <br/>
  <h5 class="goldText">{{artworkTitle}}</h5> -->
  <br/>
  <br/>
</div>


<!-- <artwork-detail-component [category]='menuCategory' [subCategory]='menuSubCategory' ></artwork-detail-component> -->
