<span class="modal-header">Artwork Details</span>
<mat-dialog-content class="mat-dialog">
  <div class="goldBarPanelHeading">
    <span class="title">{{ artwork.title }}</span>
</div>
<div>
    <div class="text-center">
        <img class="fullImage center-block" [src]='artwork.imageUrl'>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>

