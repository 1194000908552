<div class="user-profile-menu-container">
  <ng-container *ngIf="isUserLoggedIn; else showLoginButton">
    <div class="mt-3 mb-4 d-flex justify-content-center" *ngIf="isAuthenticationEnabled()">
      <button class="login-button" [matMenuTriggerFor]="loginmenu" mat-fab color="primary" aria-label="Example icon button with a home icon">
        <h4 style="margin-top: 16px">{{ userInfo?.firstName[0] }}</h4>
      </button>
        <mat-menu class="mat-menu" #loginmenu="matMenu" yPosition="below">
          <div style="margin: 15px">
            <div class="text-center">{{ userInfo?.fullName }}</div>
            <div class="text-center">{{ userInfo?.email }}</div>
            <button class="mt-5 gold-text" mat-raised-button (click)="logoutUser()"><mat-icon>logout</mat-icon> Sign out</button>
          </div>
        </mat-menu>
    </div>
  </ng-container>
  <ng-template #showLoginButton>
    <div class="mt-4 mb-4 d-flex justify-content-center align-items-center" *ngIf="isAuthenticationEnabled()">
        <button class="gold-text" mat-raised-button (click)="loginUser()"><mat-icon>login</mat-icon> Sign in</button>
    </div>
    <mat-divider></mat-divider>
  </ng-template>
</div>
<div class="divider">
  <mat-divider></mat-divider>
</div>

<mat-nav-list side-nav>
    <ng-container *ngFor="let item of navbarDefinition">
        <!-- Top-Level: No children -->
        <div *ngIf="!item.children; else topMenuWithChildren">
          <ng-container *ngIf="hasRequiredRoles(item)">
            <button mat-menu-item [routerLink]="item.route" [disabled]="item.isDisabled" (click)="onSidenavClose()">
                <mat-icon>{{item.icon}}</mat-icon>{{ item.title}}
            </button>
          </ng-container>
        </div>

        <!-- Top-Level Has Children -->
        <ng-template #topMenuWithChildren>
          <ng-container *ngIf="hasRequiredRoles(item)">
            <button class="gold-text" mat-menu-item [matMenuTriggerFor]="menu">
                  <mat-icon>{{ item.icon }}</mat-icon> {{ item.title }}
                  <!-- <span class="nav-caption menuItem"></span> -->
                  <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </ng-container>

            <mat-menu #menu="matMenu">
                <!-- Loop through sub-menu -->
                <div *ngFor="let child of item.children">
                    <ng-container *ngIf="child?.type === 'divider'; else realMenuItem">
                        <mat-divider></mat-divider>
                    </ng-container>

                    <ng-template #realMenuItem>
                        <!-- Child-Level - No Children -->
                        <ng-container *ngIf="!child.children; else childWithChildren">
                            <button class="gold-text" mat-menu-item [routerLink]="child.route" [disabled]="child.isDisabled" (click)="onSidenavClose()">
                                <mat-icon>{{ child.icon }}</mat-icon>{{ child.title }}
                            </button>
                        </ng-container>

                        <!-- Child-Level with Children -->
                        <ng-template #childWithChildren>
                            <button mat-menu-item [matMenuTriggerFor]="sub_menu" [routerLink]="child.route" [disabled]="child.isDisabled" (click)="onSidenavClose()">
                                <mat-icon>{{ child.icon }}</mat-icon>{{ child.title }}
                            </button>
                            <mat-menu #sub_menu="matMenu">
                                <div *ngFor="let grandChild of child.children">
                                    <ng-container *ngIf="grandChild?.type === 'divider'; else realSubMenuItem">
                                        <mat-divider></mat-divider>
                                    </ng-container>

                                    <ng-template #realSubMenuItem>
                                        <!-- Assume no further child menus needed -->
                                        <button class="gold-text" mat-menu-item [routerLink]="grandChild.route" [disabled]="grandChild.isDisabled" (click)="onSidenavClose()">
                                            <mat-icon>assignment_ind</mat-icon>{{ grandChild.title }}
                                        </button>
                                    </ng-template>
                                </div>
                            </mat-menu>
                        </ng-template>
                    </ng-template>
                </div>
            </mat-menu>
        </ng-template>
    </ng-container>
</mat-nav-list>
