// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./ienvironment";

export const environment: IEnvironment = {
  name: 'local',
  production: false,
  rootApiUrl: 'http://localhost:30522/', //'https://localhost:44327/', //'http://localhost:30522/',
  apiScopes: ['api://bb4c6d42-e892-47e8-872c-d0cf4c8704f9/access_as_user'],
  graphBaseUri: 'https://graph.microsoft.com/v1.0',
  oAuthSettings: {
    clientId: '6ce40f65-9c6e-4da2-a0db-f737d94877eb', //'f6a88d97-f33d-4e39-911d-9b8fa9dcee35',
    authority: 'https://login.microsoftonline.com/common', //'https://login.microsoftonline.com/9253a53e-dae7-4db4-81b3-2aef2329d070',
    redirectUri: 'http://localhost:4200', //'https://localhost:4200',
    scopes: ['user.read', 'openid', 'profile']
  },
  yahooOAuthSettings: {
    clientId: 'dj0yJmk9QlBCajlmVWlmb3VaJmQ9WVdrOWVHMWlhbmh5Y3pjbWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTE1',
    loginUrl: 'https://api.login.yahoo.com/oauth2/request_auth',
    tokenUrl: 'https://api.login.yahoo.com/oauth2/get_token',
    redirectUri: 'https://localhost:4200/login',
    scope: 'openid',
  },
  twitterOAuthSettings: {
    clientId: 'U2NNU00ycFh3VDVrd0M2V3NnMks6MTpjaQ',
    loginUrl: 'https://twitter.com/i/oauth2/authorize',
    tokenUrl: 'https://api.twitter.com/oauth2/token',
    redirectUri: 'http://localhost:4200/login',
    scope: 'users.read',
  },
  appInsights: {
    instrumentationKey: "5b7dc42d-5e05-43d7-9746-4caa45ba58fd",
    connectionString: "InstrumentationKey=5b7dc42d-5e05-43d7-9746-4caa45ba58fd;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/;ApplicationId=c07356b5-036f-478d-a2cd-3b0354ef45df"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
