import { Component } from "@angular/core";

@Component({
  selector: 'app-accolades',
  templateUrl: './accolades.component.html',
  styleUrl: './accolades.component.scss'
})
export class AccoladesComponent {

}
