import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  readonly isMicrosoftAuthenticationEnabled: boolean = false;
  readonly isGoogleAuthenticationEnabled: boolean = false;
  readonly isFacebookAuthenticationEnabled: boolean = false;
  readonly isAmazonAuthenticationEnabled: boolean = false;

  isSocialMediaAuthenticationEnabled(): boolean {
    return this.isMicrosoftAuthenticationEnabled
            && this.isGoogleAuthenticationEnabled
            && this.isFacebookAuthenticationEnabled
            && this.isAmazonAuthenticationEnabled;
  }
}
