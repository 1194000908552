import { Injectable } from "@angular/core";
import { UserInfo } from "../models/user-info.model";
import { authenticationProvider, ProviderName } from "./social-media-auth.service";

export interface ILocalStorage {
  providerName?: string,
  code?: string,
  state?: string,
  codeVerifier?: string;
  userInfo: string
}

export type localStoragePropertyNames = 'providerName' | 'code' | 'state' | 'codeVerifier' | 'userInfo';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(){}

  // save(providerName: ProviderName, key?: string, value?: string) {
  //   let providerFromCache = localStorage.getItem(providerName);

  //   if (!providerFromCache) {
  //     localStorage.setItem(authenticationProvider, providerName);
  //   }

  //   if (!key || !value) {
  //     return;
  //   }

  //   let node:ILocalStorage = JSON.parse(providerFromCache);
  //   node[key] = value;

  //   // localStorage.removeItem(keyName);
  //   localStorage.setItem(providerName, JSON.stringify(node));
  // }

  // save(providerName: ProviderName, key: string, value: string) {
  save(key: localStoragePropertyNames, value: string) {
    if (!key || !value) {
      return;
    }

    let authProviderNode = localStorage.getItem(authenticationProvider);

    if (!authProviderNode) {
      const newAuthProviderObj = {
        [key]: value
      }
      localStorage.setItem(authenticationProvider, JSON.stringify(newAuthProviderObj));
    } else {
      let jsonObj: ILocalStorage = JSON.parse(authProviderNode);
      jsonObj[key] = value;
      localStorage.setItem(authenticationProvider, JSON.stringify(jsonObj));
    }

    // if (!node) {
    //   const authProviderSettings = {
    //     'providerName': providerName,
    //     key: value
    //   }
    //   localStorage.setItem(authenticationProvider, JSON.stringify(authProviderSettings));
    // }

    // let node:ILocalStorage = JSON.parse(providerFromCache);
    // node[key] = value;

    // // localStorage.removeItem(keyName);
    // localStorage.setItem(providerName, JSON.stringify(node));
  }


  // get(providerName:ProviderName): ILocalStorage {
  //   let fromCache = localStorage.getItem(providerName);

  //   return JSON.parse(fromCache);
  // }

  getAuthSettings(): ILocalStorage {
    let fromCache = localStorage.getItem(authenticationProvider);

    return JSON.parse(fromCache);
  }

  removeAuthenticationProviderSettings(): void {
    localStorage.removeItem(authenticationProvider);
  }

  clear(): void {
    localStorage.clear();
  }
}
