import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { ProviderName } from './social-media-auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TwitterLoginService {
  readonly providerCacheKey:ProviderName = 'Twitter';

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {}

  private strRandom(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  sendAuthRequest() {
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128);
    this.localStorageService.save('providerName', this.providerCacheKey);
    this.localStorageService.save('state', state);
    this.localStorageService.save('codeVerifier', codeVerifier);

    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(
      CryptoJS.enc.Base64
    );
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');

    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.twitterOAuthSettings.clientId,
      'scope=' + environment.twitterOAuthSettings.scope,
      'code_challenge=' + codeChallenge,
      'code_challenge_method=plain',
      'redirect_uri=' +
        encodeURIComponent(environment.twitterOAuthSettings.redirectUri),
    ];
    window.location.href =
      environment.twitterOAuthSettings.loginUrl + '?' + params.join('&');
  }

  getAccessToken(code: string, state: string) {
    const cache = this.localStorageService.getAuthSettings();

    if (state !== cache.state) {
      alert('Invalid state');
      return;
    }
    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('code_verifier', cache.codeVerifier)
      .append('redirect_uri', environment.twitterOAuthSettings.redirectUri)
      .append('client_id', environment.twitterOAuthSettings.clientId);

    this.httpClient
      .post(environment.twitterOAuthSettings.tokenUrl, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': "GET,POST,OPTIONS,DELETE,PUT"
        },
      })
      .subscribe({
        next: (response) => {
          const data = response;
        },
        error: (err) => {
          console.log(err);
        }
      });
  }
}
