import { Injectable } from "@angular/core";
import { ofEntityOp, EntityAction, EntityOp, OP_SUCCESS, OP_ERROR} from "@ngrx/data";
import { Actions } from "@ngrx/effects";

import { filter } from "rxjs/operators";
import { ToastService } from "../toast.service";

@Injectable({
  providedIn: 'root'
})
export class ngrxDataToastService {
  operationName = '';
  constructor(private actions$: Actions, private toast: ToastService) {
  }

  initializeSubscription(): void {
    this.actions$
      .pipe(
        ofEntityOp(),
        filter(
          (ea: EntityAction) =>
            /* Ignore query operations */
            !ea.payload.entityOp.startsWith('@ngrx/data/query')
        )
      )
      .subscribe(action => {
        switch(action.payload.entityOp) {
            case EntityOp.SAVE_ADD_ONE_SUCCESS:
            case EntityOp.SAVE_ADD_MANY_SUCCESS:
            case EntityOp.SAVE_ADD_ONE_ERROR:
            case EntityOp.SAVE_ADD_MANY_ERROR: {
              this.operationName = 'Added';
              break;
            }

            case EntityOp.SAVE_DELETE_ONE_SUCCESS:
            case EntityOp.SAVE_DELETE_MANY_SUCCESS:
            case EntityOp.SAVE_DELETE_ONE_ERROR:
            case EntityOp.SAVE_DELETE_MANY_ERROR: {
              this.operationName = 'Deleted';
              break;
            }


            case EntityOp.SAVE_UPDATE_ONE_SUCCESS:
            case EntityOp.SAVE_UPDATE_MANY_SUCCESS:
            case EntityOp.SAVE_UPDATE_ONE_ERROR:
            case EntityOp.SAVE_UPDATE_MANY_ERROR:

            case EntityOp.SAVE_UPSERT_ONE_SUCCESS:
            case EntityOp.SAVE_UPSERT_MANY_SUCCESS:
            case EntityOp.SAVE_UPSERT_ONE_ERROR:
            case EntityOp.SAVE_UPSERT_MANY_ERROR: {
              this.operationName = 'Updated';
              break;
            }
        }

        if (action.payload.entityOp.endsWith(OP_SUCCESS)) {
          this.toast.showStdSuccessToast(this.operationName);
        } else if (action.payload.entityOp.endsWith(OP_ERROR)) {
          this.toast.showStdErrorToast(this.operationName, action.payload.error)
        }
      });
  }
}
