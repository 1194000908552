<mat-dialog-content>
  <div>
    <div class="goldBarPanelHeading">
        <span class="artwork-title">Login with Social Media</span>
    </div>
    <div>

      <div class="form-container">
        <div class="social-media-logins">
          <asl-google-signin-button
          type="standard"
          size="large"
          width="250px"
          text="signin_with"
          theme="filled_blue"
        ></asl-google-signin-button>

          <button
            (click)="login('Microsoft')"
            mat-raised-button
            class="microsoftButton"
          >
            <i class="fa fa-brands fa-windows"></i>Login with Microsoft
          </button>

          <!-- <button
              (click)="login('Twitter')"
              mat-raised-button
              class="twitterButton"
            >
              <i class="fa fa-brands fa-twitter"></i>Login with Twitter
            </button> -->
          <button
            (click)="login('Facebook')"
            mat-raised-button
            class="facebookButton"
          >
            <i class="fa fa-brands fa-facebook"></i>Login with Facebook
          </button>
          <button
            (click)="login('Amazon')"
            mat-raised-button
            class="amazonButton"
          >
            <i class="fa fa-brands fa-amazon"></i>Login with Amazon
          </button>
          <!-- <button
              (click)="login('Yahoo')"
              mat-raised-button
              class="yahooButton"
            >
              <i class="fa fa-brands fa-yahoo"></i>Login with Yahoo
            </button> -->
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
