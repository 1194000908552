import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-bio',
  templateUrl: './my-bio.component.html',
  styleUrl: './my-bio.component.scss'
})
export class MyBioComponent implements OnInit {
  ngOnInit(): void {
  }


}
