
    <span class="modal-header">Artwork Details</span>
    <mat-dialog-content>
      <div *ngIf='artwork'>
        <div class="goldBarPanelHeading">
            <span class="artwork-title">{{ artwork.title}}</span>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6 artwork-image-container">
                    <img class="artworkImage" [src]='artwork.imageUrl' (click)="setCurrentImage(artwork.imageUrl)">
                    <div id="enlargeImageBtn" role="button" data-target="#imageViewer" data-toggle="modal" (click)="setCurrentImage(artwork.imageUrl)">
                        <mat-icon>zoom_in</mat-icon>Zoom In
                    </div>
                </div>
                    <div class="col-md-6 artworkBody text-left">
                        <br/>
                        <div><span class="whiteText">Description: </span><span class="artInfo">{{ artwork.description }}</span></div>
                        <br/>
                        <div><span class="whiteText">Medium: </span><span class="artInfo">{{ artwork.medium }}</span></div>
                        <div><span class="whiteText">CreateDate: </span><span class="artInfo">{{ artwork.createDate }}</span></div>
                        <div><span class="whiteText">Artist: </span><span class="artInfo">{{ artwork.artistName }}</span></div>
                        <div><span class="whiteText">Courtesy Of: </span><span class="artInfo">{{ artwork.courtesy }}</span></div>
                        <div><span class="whiteText">Mantra: </span><span class="artInfo">{{ artwork.mantra }}</span></div>
                        <hr/>
                        <span>Exquisite high-resolution limited edition Giclee prints (printed on durable archival canvas using pigment-based ink, signed by the artist) available for purchase</span>&nbsp;
                        <br/><br/>
                        <button class="btn btn-outline-warning btn" id="btnBuy" (click)="buyGicleePrints()" type="button" data-dismiss="modal">Buy Giclee Prints</button>
                        <hr/>
                        <div class="panel panel-default">
                            <div class="panel-heading">Artwork Details</div>
                            <div class="panel-body">
                                <span *ngFor="let subImage of artwork.subImages">
                                    <img class="artDetailImage" src="{{subImage.imageUrl}}" (click)="setCurrentImage(subImage.imageUrl)" role="button" data-target="#imageViewer" data-toggle="modal"/>
                                </span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <br/>

        <mat-tab-group>
          <mat-tab label="About the Deity">
            <div class="panel panel-default">
              <div class="tab-body">
                      {{artwork.description}}
                      <br/><br/>{{artwork.significance}}<br/><br/>
                      <h4>Resources:</h4>
                      <ul>
                          <li *ngFor="let link of artwork.subjectHyperlinks">
                              <a [href]="link" target="_blank">Click for Details</a>
                          </li>
                      </ul>
              </div>
          </div>
          </mat-tab>
          <mat-tab label="About the Work">
            <div class="panel panel-default">
              <div class="tab-body ">
                  This painting was commissioned by {{artwork.courtesy}}.<br/><br/>
                      It is created with utmost respect for my late teacher, and the Mendri style of Tibetan thangka painting. This work reflects years of my training, my aspirations to do the best, and my belief that a good painting could cultivate tremendous benefit.
                      <br/><br/>As a Thankga painter, one must be aware of multiple dimensions of cause and effect through the quality of ones work as it endures attention of critical eyes, and becomes a subject of negative comments. To paraphrase my teacher, a thanga painter is in a unique position. One could accumate good Karma by creating superb quality of paintings, or could accumate bad Karma by creating poor, sub-standard images of deities.
              </div>
          </div>
          </mat-tab>
          <!-- <mat-tab label="About the Artist">
            <div class="panel panel-default">
              <div class="tab-body">
                  This painting is created by Dhawa D Ngoche. See <a href="">artist's bio</a>
              </div>
          </div>
          </mat-tab> -->
        </mat-tab-group>
    </div>
<!-- <imageViewer [currentImageUrl]='artwork.imageUrl' [currentImageTitle]='artwork.title'></imageViewer> -->

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
