import { Component, Input, Output, OnInit, Inject } from "@angular/core";
import { ArtworkModel } from "../models/artwork.model";
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageViewerComponent } from "../imageViewer/imageViewer.component";
import { Observable, Subject } from "rxjs";
import { ArtworkService } from "../services/artwork.service";

export interface DialogData {
  // artworkId: number;
  // title: string,
  // imageUrl: string,
  // description: string,
  // medium: string,
  // createDate: string,
  // artistName: string,
  // mantra: string,
  // courtesy: string,
  // significance: string,
  // subjectHyperlinks: string[],
  // subImages: Array<ArtworkModel>
  artwork: ArtworkModel
}

@Component({
    selector: "artwork-detail-component",
    templateUrl: "./artwork-detail.component.html",
    styleUrls: ["./artwork-detail.component.css"]
})

export class ArtworkDetailComponent implements OnInit {
    errorMessage: string;
    selectedImageUrl: string;
    @Input() category: string;
    @Input() subCategory: string;
    artwork: ArtworkModel;

    constructor(
      @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
      public dialog: MatDialog,
      private artworkService: ArtworkService,
      private _router : Router, private _route : ActivatedRoute) {
        this.artwork = this.dialogData.artwork;
    }

    ngOnInit(): void {
    }

    buyGicleePrints() : void {

        //this._route.navigateByUrl('/ArtworkShop');
        //this._route.params.subscribe(params => { this.artwork = <any> params['artwork']});

        // console.log('### ' + this.artworkId);
        // console.log('### ' + this.artworkTitle);

        this.artworkService.closeArtworkDetailModal();

      // setTimeout(() => this._router.navigate(['/shop', this.artwork.artworkId]), 0);
      setTimeout(() => this._router.navigate(['/prints']), 0);

        // this._router.navigate(['/Shop', this.artwork.artworkId, this.artwork.title, this.artwork.imageUrl]);
        //this._router.navigateByUrl('/ArtworkShop/10');
    }

    setCurrentImage(selectedImageUrl: string) {
        this.selectedImageUrl = selectedImageUrl;

        const dialogRef = this.dialog.open(ImageViewerComponent, {
          data: {
            imageUrl: selectedImageUrl,
            title: this.artwork.title
          },
        });


        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
    }
}
