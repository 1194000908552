import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { delay } from "rxjs/operators";
//import * as _ from lo;
import { environment } from "src/environments/environment";
import { MicrosoftUser } from "../models/microsoft-user.model";
import { AuthService } from "./auth-service";



// export class UserInfo {
//   displayName?: string;
//   email?: string;
//   avatar?: string;
//   timeZone?: string;
// }

export class UserRole {
  constructor(public claimName: string, public hasRole: boolean = false) {}
}

/*
  Role claim names defined in Azure
*/
export enum RoleClaimName {
  ADUser = 'User',
  SocialUser = 'User',
  Admin = 'kikisoso_az_admin'
}

/* Define a type */
export type UserRoleName = | RoleClaimName.ADUser | RoleClaimName.Admin | RoleClaimName.SocialUser;

export type UserRoles = {[K in UserRoleName]: UserRole}

export class LocalStorageKeyConstants {
  static User: string = 'user';
}

@Injectable({
    providedIn: 'root'
  })
  export class UserRoleService {
    user: MicrosoftUser = new MicrosoftUser();
    errorMessage: string = '';
    private refreshRoleClaimsSubject = new BehaviorSubject<string[]>([]);
    private authenticatedUserSubject = new Subject<MicrosoftUser>();
    roleClaims$: Observable<string[]>;
    user$: Observable<MicrosoftUser>;

    private _roles: UserRoles = {
      [RoleClaimName.ADUser]: new UserRole(RoleClaimName.ADUser),
      [RoleClaimName.Admin]: new UserRole(RoleClaimName.Admin)
    }
    isUserDataLoading = false;

    constructor(private _httpClient: HttpClient, private authService: AuthService) {
      this.roleClaims$ = this.refreshRoleClaimsSubject.asObservable();
      this.user$ = this.authenticatedUserSubject.asObservable();

      this.setUserAuthStateChangeSubscription();
    }

    get roles(): UserRole[] {
      return _.values(this._roles);
    }

  //   fetchUser() {
  //     this.http
  //       .get('https://archive.org/metadata/TheAdventuresOfTomSawyer_201303').pipe(delay(3000))
  //       .subscribe((res) => {
  //         console.log(res);
  //       });
  //  }

  setUserAuthStateChangeSubscription(): void {
    this.authService.userInfo$
          .subscribe(userInfo => {
            if (userInfo?.isAuthenticated && !this.authService.userInfo?.claims) {
              this.getUserRoleClaims();
            }
          });
  }

  private getUserClaims(): Observable<any> {
    return this._httpClient.get<any>(`${environment.rootApiUrl}api/User`);
  }

    getUserRoleClaims(): void {

      this.isUserDataLoading = true;
      this.getUserClaims()
        .subscribe({
          next: (claims:string[]) => {
            alert('xxx');
            // this.processUserData(claims);
            if (!_.isEqual(claims, this.authService.userInfo?.claims)) {
              this.authService.updateUserClaims(claims);
            }
          },
          error: (err) => {
            if (err.status === 403 || err.status === 401) {
              this.errorMessage = 'Forbidden! Access Denied!';
            } else if (err) {
              this.errorMessage = err.statusText;
            }
          },
            complete: () => {}
        })
    }


    // processUserData(data: any): void {
    //   this.user = data;
    //   // this.parseUserClaims();
    //   // this.setRoles();
    //   // this.setUserInCache(this.user);
    //   this.refreshRoleClaimsSubject.next(this.user.RoleClaimNames!);
    //   this.authenticatedUserSubject.next(this.user);
    //   this.isUserDataLoading = false;
    // }

    // private parseUserClaims(): void {
    //   const claimsArray = this.user?.claims?.toString().split(',');
    //   if (claimsArray) {
    //     this.user.RoleClaimNames = [];

    //     for (let x of claimsArray) {
    //       const kvp = x.split('role:');
    //       const fNameKvp = x.split('givenname');
    //       const lNameKvp = x.split('surname');

    //       if (kvp[1]) {
    //         this.user.RoleClaimNames.push(kvp[1]);
    //       }

    //       if (!this.user.firstName && fNameKvp) {
    //         this.user.firstName = fNameKvp[1];
    //       }

    //       if (!this.user.lastName && lNameKvp) {
    //         this.user.lastName = lNameKvp[1];
    //       }
    //     }
    //   }
    //   this.TESTING_TESTING();
    // }

    // private TESTING_TESTING(): void {
    //   // this.user?.RoleClaimNames?.push('User');
    //   // this.user.RoleClaimNames = this.user.RoleClaimNames?.filter(x => x !== 'Admin');
    // }

    // private setRoles(): void {
    //   if (!this.user || !this.user.RoleClaimNames) {
    //     return;
    //   }

    //   const roleNames = _.cloneDeep(this.user.RoleClaimNames);

    //   const userHasRole = (roleToCheck: string) => {
    //     const hasRole = _.includes(roleNames, roleToCheck);
    //     if (hasRole) {
    //       _.remove(roleNames, (r: string) => r === roleToCheck);
    //     };
    //     return hasRole;
    //   };

    //   /** NOte: For some reason Lodash _.forIn, _.forEach does not work */
    //   // _.forEach(this.roles, (value: UserRole, key: string) => {
    //   //   this.roles[+key].hasRole = userHasRole(value.claimName);
    //   // });

    //   for (let i = 0; i < this.roles.length; i++) {
    //     this.roles[i].hasRole = userHasRole(this.roles[i].claimName);
    //   }
    // }

    // hasRole(roleToCheck: UserRoleName): boolean {
    //   if (!roleToCheck) {
    //     return false;
    //   }

    //   if (!this.user || !this.user.RoleClaimNames) {
    //     this.loadRolesFromCache();
    //   }

    //   const role: UserRole = this._roles[roleToCheck];

    //   if (!role) {
    //     return false;
    //   } else {
    //     return role.hasRole;
    //   }
    // }

    // hasUserData(): Observable<boolean> {
    //   return of(this.user.isAuthenticated);
    // }

    // hasRoleIn(...rolesToCheck: UserRoleName[] | RoleClaimName[]): boolean {
    //   return _.some(rolesToCheck, (role:RoleClaimName) => this.hasRole(role));
    // }

    // loadRolesFromCache(): string[] {
    //   const userFromCache = localStorage.getItem(LocalStorageKeyConstants.User);

    //   if (!userFromCache) {
    //     return [];
    //   }

    //   this.user = JSON.parse(userFromCache);
    //   this.setRoles();

    //   if (!this.user || !this.user.RoleClaimNames) {
    //     return [];
    //   }

    //   if (this.user.RoleClaimNames) {
    //     this.refreshRoleClaimsSubject.next(this.user.RoleClaimNames);
    //   }
    //   this.authenticatedUserSubject.next(this.user);
    //   return this.user.RoleClaimNames;
    // }

    // setUserInCache(user:MicrosoftUser): void {
    //   localStorage.removeItem(LocalStorageKeyConstants.User);
    //   localStorage.setItem(LocalStorageKeyConstants.User, JSON.stringify(user));
    // }
}
