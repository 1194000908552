import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Product } from "../models/product.model";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  get(artworkId: number): Observable<Product> {
    return of(
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' })
  }

  getAll(): Observable<Product[]> {
    return of(
      this.getTestProducts()
    );
  }

  getTestProducts(): Product[] {
    return [
      { id: 1, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 3, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 4, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 5, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 6, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 7, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 10, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 11, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 12, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 13, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 14, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 16, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 17, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 18, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 19, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 20, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 21, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 22, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 23, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 24, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      { id: 25, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
    ];
  }

  getProducts(): Product[] {
    return [
      // { id: 1, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/28o7tR7jdgLRgsU28e' },
      // { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/5kA4hFavpgLR7Wo5kp' },
      // { id: 3, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/14kbK71YT67ddgIeUY' },
      // { id: 4, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/3cs9BZ5b567d7Wo4gj' },
      // { id: 5, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/4gwcOb0UP3Z52C4bIK' },

      // { id: 6, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 7, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      // { id: 10, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 11, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 12, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 13, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 14, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      // { id: 16, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 17, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 18, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 19, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 20, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      // { id: 21, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 22, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 23, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 24, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },
      // { id: 25, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/test_4gwbJt0SSfxHb7y8wA' },

      { id: 1, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/28o7tR7jdgLRgsU28e' },
      { id: 2, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/5kA4hFavpgLR7Wo5kp' },
      { id: 3, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/14kbK71YT67ddgIeUY' },
      { id: 4, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/3cs9BZ5b567d7Wo4gj' },
      { id: 5, artworkId: 1, name: 'Shakyamuni Buddha', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/4gwcOb0UP3Z52C4bIK' },

      { id: 6, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/14k15tgTN1QXa4w3cj' },
      { id: 7, artworkId: 2, name: 'Green Tara with Avalokiteshvara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/28og0neLF2V13G828g' },

      { id: 10, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/6oEeWjgTNcvB7WoeVd' },
      { id: 11, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/7sI3dB6f99jpfoQ9AU' },
      { id: 12, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/9AQ15tavp5397Wo4gB' },
      { id: 13, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/5kA29xfPJ0MT2C44gC' },
      { id: 14, artworkId: 3, name: 'Four Armed Chenrezig', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/3cs6pN9rlgLRccEdRd' },

      { id: 16, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/dR64hFbztbrx1y000e' },
      { id: 17, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/9AQ15tavpcvBdgI9AP' },
      { id: 18, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/00g29x7jddzF1y0bIY' },
      { id: 19, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/fZe8xV9rl9jp7WodR7' },
      { id: 20, artworkId: 4, name: 'Nyamme Sherab Gyaltsen', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/cN27tRfPJcvB2C4eVc' },

      { id: 21, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/5kAbK75b55394KcfZ7' },
      { id: 22, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/9AQ5lJdHBdzF7WobIS' },
      { id: 23, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/bIYg0nbzt3Z590s5kv' },
      { id: 24, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/eVa3dB0UPgLRccEbIU' },
      { id: 25, artworkId: 5, name: 'Green Tara', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/28o3dBbzt7bhekM5kx' },

	    { id: 26, artworkId: 6, name: 'H.H. the 33rd Menri Abbot', description: 'Giclee print on canvas (scroll only)', price: 170.00, width: 11, height: 14, paymentLink: 'https://buy.stripe.com/cN24hFbztbrxb8A3cD' },
      { id: 27, artworkId: 6, name: 'H.H. the 33rd Menri Abbot', description: 'Giclee print on canvas (scroll only)', price: 230.00, width: 14, height: 17, paymentLink: 'https://buy.stripe.com/6oE6pN6f92V1b8A00s' },
      { id: 28, artworkId: 6, name: 'H.H. the 33rd Menri Abbot', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/cN23dBbztcvB0tWbJb' },
      { id: 29, artworkId: 6, name: 'H.H. the 33rd Menri Abbot', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/00gcOb0UP0MTb8AfZs' },
      { id: 30, artworkId: 6, name: 'H.H. the 33rd Menri Abbot', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/6oE01p9rlcvBgsUbJd' },

      { id: 33, artworkId: 7, name: 'Yumchen Sherab Chamma', description: 'Giclee print on canvas (scroll only)', price: 260.00, width: 16, height: 20, paymentLink: 'https://buy.stripe.com/aEUg0n32X2V190s5kK' },
      { id: 34, artworkId: 7, name: 'Yumchen Sherab Chamma', description: 'Giclee print on canvas (scroll only)', price: 290.00, width: 18, height: 24, paymentLink: 'https://buy.stripe.com/7sIcObdHB539ekM28x' },
      { id: 35, artworkId: 7, name: 'Yumchen Sherab Chamma', description: 'Giclee print on canvas (scroll only)', price: 370.00, width: 20, height: 30, paymentLink: 'https://buy.stripe.com/00g3dBavp67da4w3cA' },
    ];
  }
}
