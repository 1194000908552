import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin.component';
import { BrowserUtils } from '@azure/msal-browser';
import { ArtworksComponent } from './artwork/artworks.component';
import { ShopComponent } from './shop/artworks/shop.component';
import { RoleClaimName } from './services/role-services';
import { MyBioComponent } from './my-bio/my-bio.component';
import { MyTeacherComponent } from './my-teacher/my-teacher.component';
import { AccoladesComponent } from './accolades/accolades.component';
import { ReturnPolicyComponent } from './shop/return-policy/return-policy.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard, AuthGuardService]
  },
  {
    path: 'shop/:itemId',
    component: ShopComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'prints',
    component: ShopComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'return-policy',
    component: ReturnPolicyComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    data: { requiredRoles: [RoleClaimName.Admin]}
  },
  // { path: 'sculpting', component: ArtworksComponent },
  { path: ':category/:subcategory', component: ArtworksComponent },
  { path: 'my-bio', component: MyBioComponent },
  { path: 'my-teacher', component: MyTeacherComponent },
  { path: 'accolades', component: AccoladesComponent },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then(x => x.GalleryModule)
  },
  {path: '**', component: HomeComponent}
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
