<div class="page">
  <div >
    <h5 class="header">
      Giclee Print Return Policy
    </h5>
    <div class="mb-2 text-center">
      We appreciate your interest in our giclee prints! Before making a purchase, please take a moment to review our return policy below.
    </div>
  </div>

<ol>
  <li>
    <div class="policy-header mt-5">
      Returns and Refunds
    </div>
    <ol>
      <li>
        Eligibility: We accept returns only in specific circumstances:

        <ul>
          <li>
            Defective Prints: If you receive a damaged or defective print, please contact us within 7 days of delivery. We will promptly replace the print at no additional cost.
          </li>
          <li>
            Incorrect Shipment: In case of an order mix-up on our part (e.g., wrong size or artwork), please notify us within 7 days of delivery. We will arrange for the correct print to be sent to you.
          </li>
        </ul>
      </li>
      <li>
        Non-Eligible Returns: Unfortunately, we cannot accept returns for the following reasons:
        <ul>
          <li>Change of Mind: Once an order is placed and the artwork is printed, we are unable to accept returns due to a change of mind.</li>
          <li>Wrong Orders: In case you placed an order for the wrong artwork or size, we cannot accomodate returns due to our cost for printing the artwork.</li>
          <li>Custom Orders: Customized giclee prints (e.g., personalized colors, sizes, or alterations) are non-returnable.</li>
        </ul>
      </li>
    </ol>
  </li>
  <li>
    <div class="policy-header">
      Refund Process
    </div>
    <ol>
      <li>
        Loss Incurred: Please understand that once a giclee print is produced, we incur costs with our printing partner. As a result, we cannot offer refunds without sustaining a financial loss.
      </li>
      <li>
        Replacement or Store Credit: If your return is eligible, we will either replace the print or issue store credit for future purchases. Refunds will be considered only in exceptional cases.
      </li>
    </ol>
  </li>
  <li>
    <div class="policy-header">
      How to Initiate a Return
    </div>
    <ol>
      <li>
        Contact Us: To initiate a return, please email us at <span class="text-primary">kikisoso.com&#64;gmail.com </span>with the following details:
        <ul>
          <li>Order number</li>
          <li>Reason for return</li>
          <li>Clear photos of any defects (if applicable)</li>
        </ul>
      </li>
      <li>
        Return Shipping: If your return is approved, we will provide instructions for returning the print.
      </li>
    </ol>
  </li>
  <li>
    <div class="policy-header">
      Additional Notes
    </div>
    <ol>
      <li>
        Packaging: Please ensure that the returned print is securely packaged to prevent damage during transit.
      </li>
      <li>
        Processing Time: Once we receive the returned item, please allow up to 10 business days for processing and assessment.
      </li>
    </ol>
  </li>
</ol>
</div>
