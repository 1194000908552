<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Venerable Sangye Yeshe</mat-card-title>
    <mat-card-subtitle>Master Thangka Painter</mat-card-subtitle>
  </mat-card-header>
  <!-- <img mat-card-image  id="teacher-photo" src="../../assets/GenSangyeYeshe.png" > -->
  <mat-card-content>
    <div class="content-container">
      <img id="teacher-photo" src="../../assets/GenSangyeYeshe.png" />
      <p>
        Frankly speaking, my teacher was one of the most graceful-looking
        and physically prominent monks. He embodied the purity of a humble monk,
        automatically gaining your respect with his sincere and kind smile. I'd
        sometimes hear him chuckling and chatting with highly regarded
        senior lamas that taught and researched at LTWA. My teacher
        hailed from eastern Tibet as a Tewu-Khampa and was renowned as the
        foremost master of the Men-dri style of Tibetan thangka painting.
      </p>
      <p>
        According to my older classmates, His Holiness the Dalai Lama was
        extremely impressed by an Avalokiteshvara painting my teacher had
        created. Consequently, His Holiness personally tasked my teacher and the
        Library of Tibetan Works & Archives (LTWA) with opening a thangka
        painting school to teach his mastery under the direct supervision and
        sponsorship provided by His Holiness’s Private Office.
      </p>
      <p>
        He had kindly accepted and worked closely with H.H.'s Private Office in
        subsequent years, frequenting private audiences with H.H. In fact, our
        first painting which we earned after years of sketching practice was for
        H.H.'s temple in Dharamsala. Each student proudly painted a thangka of
        Shakyamuni Buddha as the utmost test of their skill. Since it was our
        very first painting, my teacher had us use his master copy of sketch to keep the paintings uniform.
      </p>
      <p>
        After the first batch of students graduated in 1986 (after 9 years), our
        school became widely known as the de facto standard and earned a great
        reputation. LTWA extended its support, offering scholarships to new
        students, while our teacher continued teaching for many years.
      </p>
      <p>
        Looking back, I am extremely fortunate to have had such a great
        teacher. He taught us not only the art of thangka painting, but also how to be
        better human beings. Words were often not necessary, as much of the
        learning through him was contemplative. Through this method, I believe
        the students learned to seek deeper meaning in their lives as thangka
        painters.
      </p>
      <p>
        I deeply cherish the years I was fortunate enough to study under him,
        seeing him every day, and being able to work closely with him as I
        matured and blossomed as a young thangka painter.
      </p>
    </div>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
</mat-card>
