import { Injectable } from '@angular/core';
import {
  FacebookLoginProvider,
  SocialAuthService,
  SocialUser,
  AmazonLoginProvider,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../models/user-info.model';
import { AuthService } from './auth-service';
import { UserRoleService } from "./role-services";

import { LocalStorageService } from './local-storage.service';
import { TwitterLoginService } from './twitter-login.service';
import { YahooLoginService } from './yahoo-login.service';

export type ProviderName =
  | 'GOOGLE'
  | 'Facebook'
  | 'Twitter'
  | 'Microsoft'
  | 'Yahoo'
  | 'Amazon'
  | 'Pinterest'
  | 'LinkedIn'
  | 'Github'
  | 'Instagram';

export enum ProviderNames {
  'Google' = 'GOOGLE',
  'Facebook' = 'Facebook',
  'Twitter' = 'Twitter',
  'Microsoft' = 'Microsoft',
  'Yahoo' = 'Yahoo',
  'Amazon' = 'Amazon',
  'Pinterest' = 'Pinterest',
  'LinkedIn' = 'LinkedIn',
  'Github' = 'Github',
  'Instagram' = 'Instagram'
}
export const authenticationProvider = 'authenticationProvider';

@Injectable({
  providedIn: 'root',
})
export class SocialMediaAuthService {
  // authState: Observable<SocialUser>;
  userInfo: UserInfo;

  private userInfoSubject = new BehaviorSubject<UserInfo>(null);
  userInfo$ = this.userInfoSubject.asObservable();
  socialMediaProviders = ['GOOGLE', 'Facebook', 'Amazon', 'Twitter'];

  constructor(
    public socialAuthService: SocialAuthService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private userRoleService: UserRoleService,
    // private yahooLoginService: YahooLoginService
    // private twitterLoginService: TwitterLoginService
  ) {
      this.intializeSubscriptions();
  }

  intializeSubscriptions(): void {
    this.socialAuthService.authState
          .subscribe(x => {
            // this.socialAuthService.getAccessToken("GOOGLE")
            const providerName = x.provider;
            this.notifyLoginState(providerName);

            if (x.idToken) {
              // this.localStorageService.save('providerName', ProviderNames.Google);
              this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID)
                .then((x) => {
                  // alert(x);
                  console.log("Google access token: ", x);
                  this.userRoleService.getUserRoleClaims();

                })
              this.userRoleService.getUserRoleClaims();
            }
          });
  }

  login(providerName: ProviderName): void {

    const hasExistingProvider =
      !!this.localStorageService.getAuthSettings()?.providerName;

    if (hasExistingProvider) {
      /* Means user is logged in with an existing provider */
      this.localStorageService.removeAuthenticationProviderSettings();
    }


    /* NOTE: The new Google Identity Services no longer uses socialAuthService.signin.
      See https://developers.google.com/identity/sign-in/web/sign-in
    */
    switch (providerName) {
      case 'Facebook': {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(() => {
          this.notifyLoginState(providerName);
        });
        break;
      }
      case 'Amazon': {
        this.socialAuthService.signIn(AmazonLoginProvider.PROVIDER_ID).then(() => {
          this.notifyLoginState(providerName);
        });
        break;
      }
      case 'Microsoft': {
        this.authService.login();
        break;
      }
      // case 'Twitter': {
      //   this.twitterLoginService.sendAuthRequest();
      //   break;
      // }
      // case 'Yahoo': {
      //   this.yahooLoginService.sendAuthRequest();
      //   break;
      // }
      default: {
        // Notify unknown company name
        break;
      }
    }
  }

  logout(): void {
    const providerName =
      this.localStorageService.getAuthSettings()?.providerName;
      // dingo

    switch (providerName) {
      case 'GOOGLE': {
        this.socialAuthService.signOut().then(() => {
          this.notifyLogoutState(providerName);
        });
        break;
      }
      case 'Facebook': {
        this.socialAuthService.signOut(true).then(() => {
          alert('bboo');
          this.notifyLogoutState(providerName);
        }).catch(() => {
          this.socialAuthService.authState
                .subscribe(x => alert("Signed out"));
        });
        break;
      }
      case 'Amazon': {
        this.socialAuthService.signOut().then(() => {
          this.notifyLogoutState(providerName);
        });
        break;
      }
      // case 'Twitter': {
      //   this.socialAuthService.signOut().then(() => {
      //     this.notifyLogoutState(providerName);
      //   });
      //   break;
      // }
      case 'Microsoft': {
        this.authService.logout();
        break;
      }
      default: {
        // Notify unknown company name
        break;
      }
    }
  }

  notifyLoginState(providerName: string): void {
    this.socialAuthService.authState.subscribe((x) => {

      if (providerName === 'Amazon') {
        let isUserLoggedIn = x ? !!x.email && !!x.name : false;

        this.userInfo = new UserInfo(
          JSON.stringify(x.id),
          x.name,
          x.name.split(' ')[0],
          x.name.split(' ')[1],
          x.email,
          isUserLoggedIn,
          providerName
        );
      } else if (providerName === 'GOOGLE') {
        let isUserLoggedIn = x ? !!x.email && !!x.firstName : false;

        if (isUserLoggedIn) {
          this.userInfo = new UserInfo(
            JSON.stringify(x.id),
            x.name,
            x.firstName,
            x.lastName,
            x.email,
            isUserLoggedIn,
            providerName
          );
        }
      } else {
        let isUserLoggedIn = x ? !!x.email && !!x.firstName : false;

        if (isUserLoggedIn) {
          this.userInfo = new UserInfo(
            JSON.stringify(x.id),
            x.name,
            x.firstName,
            x.lastName,
            x.email,
            isUserLoggedIn,
            providerName
          );
        }
      }

      this.localStorageService.save('providerName', providerName);
      this.localStorageService.save('userInfo', JSON.stringify(this.userInfo));
      this.userInfoSubject.next(this.userInfo);
    });
  }

  notifyLogoutState(providerName: string): void {
    this.socialAuthService.authState.subscribe((x) => {
      this.userInfo = new UserInfo(
        JSON.stringify(null),
        null,
        null,
        null,
        null,
        false,
        providerName
      );

      this.localStorageService.removeAuthenticationProviderSettings();

      this.userInfoSubject.next(this.userInfo);
    });
  }

  refreshLoginState() {
    const providerName =
      this.localStorageService.getAuthSettings()?.providerName;

    if (providerName === 'Microsoft') {
      /* Do nothing, Microsoft is not part of social media */
      return;
    }

    const userInfoFromCache =
      this.localStorageService.getAuthSettings()?.userInfo;
    if (userInfoFromCache) {
      this.userInfo = JSON.parse(userInfoFromCache);

      if (this.userInfo?.isAuthenticated && !providerName) {
        alert('Fire!');
      }
    } else {
      if (!this.userInfo?.isAuthenticated) {
        this.userInfo = new UserInfo(
          JSON.stringify(null),
          null,
          null,
          null,
          null,
          false,
          providerName
        );
      }
    }

    this.userInfoSubject.next(this.userInfo);
  }
}
