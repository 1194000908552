import { DefaultDataServiceConfig } from "@ngrx/data";

const ROOT_API_URL = 'ROOT_API_URL';

export const defaultEntityServiceConfig: DefaultDataServiceConfig = {
    root: `${ROOT_API_URL}`,

    entityHttpResourceUrls: {
        Customer: {
            entityResourceUrl: `${ROOT_API_URL}\customer`,
            collectionResourceUrl: `${ROOT_API_URL}\customer`,
        }
    },

    // timeout: 3000 // request timeout
}