import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { from, lastValueFrom, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AuthService } from "../services/auth-service";
import { SocialMediaAuthService } from "../services/social-media-auth.service";

@Injectable({
    providedIn: 'root'
  })
  export class AuthGuardService  {

    constructor(private router: Router,
                private msalService: MsalService,
                private authService: AuthService,
                private socialMediaAuthService: SocialMediaAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      const isMicrosoftAuthenticated = this.authService.userInfo?.isAuthenticated;
      const isSocialMediaAuthenticated = this.socialMediaAuthService.userInfo?.isAuthenticated;

      if (!isMicrosoftAuthenticated && !isSocialMediaAuthenticated) {
        alert('You are not authorized!');
        this.router.navigate(['/']);
        // return of(false);
      }

      const requiredRoles: string[] = route.data['requiredRoles'];
      if (requiredRoles) {
        const hasRequiredRole = requiredRoles?.some(x => this.authService.userInfo.claims.some(y => y === x));

        if (!hasRequiredRole) {
          alert('You are not authorized!');
          this.router.navigate(['/']);
          // return of(false);
        }
      }

      return of(true);

      // return this.socialAuthService.authState.pipe(
      //   map((socialUser: SocialUser) => {

      //   }),
      //   tap((isLoggedIn: boolean) => {

      //     if (!isLoggedIn) {
      //       this.router.navigate(['login']);
      //     }
      //   })
      // );

      // return this.socialAuthService.authState.pipe(
      //   map((socialUser: SocialUser) => {
      //     const isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
      //     return !!socialUser || isAuthenticated;
      //   }),
      //   tap((isLoggedIn: boolean) => {

      //     if (!isLoggedIn) {
      //       this.router.navigate(['login']);
      //     }
      //   })
      // );
    }
  }
