import { ArtworkService } from "../services/artwork.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { ArtworkModel } from "../models/artwork.model";
import { MatDialog } from "@angular/material/dialog";
import { ArtworkDetailComponent } from "./artwork-detail.component";
import { forkJoin } from "rxjs";
//import { PairsPipe } from '../shared/pipes/two-columns.pipe';

@Component({
    selector : "artworks-component",
    templateUrl : "./artworks.component.html",
    styleUrls : ["./artworks.component.css"]
})

export class ArtworksComponent implements OnInit {
    errorMessage : string;
    artworks : ArtworkModel[];
    artwork : ArtworkModel;
    selectedImageUrl : string;
    @Output() selectedArtworkTitle : string;
    @Input() category : "artwork";
    @Input() subCategory : "painting";
    breadCrumb: string;

    constructor(public dialog: MatDialog, private _artworkService : ArtworkService, private route: ActivatedRoute) { }

    ngOnInit() : void {
      this.initSubscriptions();

        this.route.params.subscribe(params => {
          this.category = <any>params['category']
          this.subCategory = <any>params['subcategory'];

          // this.getTestData();
          this.getArtworks();
          this.breadCrumb = this.category ? this.category + " -> " + this.subCategory: "";
        });
    }

    initSubscriptions(): void {
      this._artworkService.closeArtworkDetailModal$
              .subscribe(x => {
                if (!!x) {
                  this.dialog.closeAll();
                }
              })
    }

    getArtworks() : void {
        this._artworkService.getArtworks()
                        .subscribe(
                            (artworks) => {
                                this.artworks = artworks.filter((item) => item.category == this.category && item.subcategory == this.subCategory)
                            },
                            (error) => this.errorMessage = <any>error);
    }

    getArtwork(artworkId: number) : void {
      this.artwork = this.artworks.find(x => x.artworkId === artworkId);
      this.selectedImageUrl = this.artwork.imageUrl;
      this.selectedArtworkTitle = this.artwork.title;

      const dialogRef = this.dialog.open(ArtworkDetailComponent, {
        data: { artwork: this.artwork }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
  }

    // TEMPORARY HACK
    // getTestData() : void {
    //     console.log("Category: {0} - SubCategory: {1}", this.category, this.subCategory);

    //     this.artworks = (<ArtworkModel[]> this._artworkService.getJsonTestFile())
    //                     .filter((item) => item.category == this.category && item.subcategory == this.subCategory);
    // }

    buy(): void {

    }

}
