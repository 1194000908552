import { NgModule } from "@angular/core";
import { EntityDataService, EntityDefinitionService } from "@ngrx/data";
import { entityMetadata } from "../entity-metadata";

@NgModule({
    imports: [],
    providers: [
        // CustomerEntityService,
    ]
})
export class RootStoreModule {
    constructor(private eds: EntityDefinitionService,
        private entityDataService: EntityDataService) {
            eds.registerMetadataMap(entityMetadata);

            /** Register custom data services */
            entityDataService.registerServices({
               // 'FooBar': fooBarCustomEntityService,
            })
        }
}